<template>
  <div>
    <el-form ref="form" :model="form" label-width="30px">
      <el-form-item>
        <span slot="label">
          <b-icon class="icon-span" name="Username"></b-icon>
        </span>
        <el-input
          v-model="form.email"
          placeholder="Email"
          type="email"
          autocapitalize="off"
          autocorrect="off"
          prop="email"
          @keyup.enter.native="onLogin"
        />
      </el-form-item>
      <el-form-item prop="pass">
        <span slot="label">
          <b-icon class="icon-span" name="Password"></b-icon>
        </span>
        <el-input
          v-model="form.password"
          placeholder="Password"
          type="password"
          :auto-complete="'off'"
          @keyup.enter.native="onLogin"
        />
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="onLogin">Login</el-button>
    <vue-apple-signin class="appleButton"></vue-apple-signin>
  </div>
</template>

<script>
import VueAppleSignin from "vue-apple-signin";
import Vue from "vue";

export default {
  props: {
    isUpgrade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  created() {
    Vue.use(VueAppleSignin, {
      clientId: "com.droplii.droplii",
      scope: "name email",
      redirectURI: "https://api.pocketoils.com/auth/apple/redirect",
      state: "STATE",
    });
  },
  methods: {
    onLogin() {
      let f = this.form;
      // let authForm =
      //   'username=' +
      //   f.email +
      //   '&password=' +
      //   f.password +
      //   '&grant_type=password'
      this.$store.dispatch("user/login", f).then(() => {
        this.$router.push("/home");
      });
    },
  },
};
</script>

<style scoped>
.icon-span {
  margin-bottom: -10px;
  padding-right: 50%;
}
.appleButton {
  margin-top: 20px;
  height: 60px;
  width: 200px;
}
</style>
