<template>
  <div>
    <br />
    <el-form ref="form" :model="viewForm" label-width="100px">
      <el-form-item label="First Name:">
        <b>{{ viewForm.firstName }}</b>
      </el-form-item>
      <el-form-item label="Last Name:">
        <b>{{ viewForm.lastName }}</b>
      </el-form-item>
      <el-form-item label="Email:">
        <div v-for="(email, i) in viewForm.emails" :key="`e-${i}`">
          <a :href="'mailto:' + email.email">{{ email.email }}</a>
        </div>
      </el-form-item>
      <el-form-item label="Phone #:">
        <div v-for="(phone, i) in viewForm.phones" :key="`p-${i}`">
          <div v-if="phone.number">
            <a class="a-pad" :href="'tel:' + phone.number">call</a>
            <a class="a-pad" :href="'sms:' + phone.number">text</a>
            <span>{{ phone.number }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="Address:">
        <div v-for="(address, i) in viewForm.addresses" :key="`a-${i}`">
          <a
            :href="'http://maps.google.com/?q=' + address.address"
            target="_blank"
            >{{ address.address }}</a
          >
        </div>
      </el-form-item>
      <el-form-item label="Notes:">
        <notes
          :notes="viewForm.notes"
          :contact="viewForm.id"
          @deleteNote="onDeleteNote"
          @addNote="onAddNote"
          @editNote="onEditNote"
        />
      </el-form-item>
      <el-button
        v-if="!viewForm.MentorId"
        round
        size="small"
        icon="el-icon-edit-outline"
        @click="$emit('edit')"
        >Edit Contact</el-button
      >
      <el-button
        v-if="!viewForm.MentorId"
        round
        size="small"
        type="danger"
        icon="el-icon-delete"
        @click="onDeleteContact"
        >Delete Contact</el-button
      >
    </el-form>
  </div>
</template>
<script>
import Notes from "@/components/contacts/Notes";
import {
  DeleteContact,
  DeletePhone,
  DeleteAddress,
  DeleteNote,
  DeleteEmail,
} from "@/graphql/mutations/EditContact.graphql";
import GetContacts from "@/graphql/queries/GetContacts.graphql";

export default {
  components: {
    Notes,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["viewForm"],
  methods: {
    notification() {
      this.$message({
        message: "Contact succesfully deleted!",
        type: "success",
        showClose: true,
      });
    },
    onAddNote(newNote) {
      this.$emit("addNote", newNote);
    },
    onDeleteNote(note) {
      this.$emit("deleteNote", note);
    },
    onDeleteContact() {
      this.$confirm(
        "This will permanently delete this contact. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          //delete phones mutations
          this.viewForm.phones.map(async (phone) => {
            await this.$apollo.mutate({
              mutation: DeletePhone,
              variables: {
                id: phone.id,
              },
            });
          });

          //delete emails mutation
          this.viewForm.emails.map(async (email) => {
            await this.$apollo.mutate({
              mutation: DeleteEmail,
              variables: {
                id: email.id,
              },
            });
          });

          //delete notes mutation
          this.viewForm.notes.map(async (note) => {
            await this.$apollo.mutate({
              mutation: DeleteNote,
              variables: {
                id: note.id,
              },
            });
          });

          //delete addresses mutation
          this.viewForm.addresses.map(async (address) => {
            await this.$apollo.mutate({
              mutation: DeleteAddress,
              variables: {
                id: address.id,
              },
            });
          });

          //delete contact mutation, and update contacts list
          await this.$apollo.mutate({
            mutation: DeleteContact,
            variables: {
              id: this.viewForm.id,
            },
            refetchQueries: [
              {
                query: GetContacts,
                variables: {
                  id: this.$store.state.user.user.profile.id,
                },
              },
            ],
          });
          this.notification();
          await this.$router.push({ name: "Contacts" });
        })
        .catch(() => {});
    },
    onEditNote(note) {
      this.$emit("editNote", note);
    },
  },
};
</script>

<style lang="css" scoped>
.a-pad {
  padding-right: 10px;
}
</style>
