import { api } from '../../api/api.js'
// import Strapi from 'strapi-sdk-javascript'
// export const api = new Strapi('http://localhost:1337')
import router from '@/router'
import {
  CONTACT_SUCCESS,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_DELETE_SUCCESS
} from '@/assets/data/messages'

const state = {
  contacts: null
}
const getters = {
  contacts: s => s.contacts
}
const actions = {
  async getContacts({ commit, rootGetters }) {
    const query = {
      profile: rootGetters['user/profileId']
    }
    const contacts = await api.getEntries('contacts', query)
    commit('SET_CONTACTS', contacts)
  },
  async createContact({ commit, rootGetters }, contact) {
    const profile = rootGetters['user/profileId']
    const contactToCreate = Object.assign(
      { firstName: contact.firstName, lastName: contact.lastName },
      { profile }
    )
    const newContact = await api.createEntry('contacts', contactToCreate)
    const contactId = newContact.id
    // I'll need to do this in the backend.
    const phone = api.createEntry('phones', {
      number: contact.phones[0].number,
      contact: contactId
    })
    const email = api.createEntry('emails', {
      email: contact.emails[0].email,
      contact: contactId
    })
    const address = api.createEntry('addresses', {
      address: contact.addresses[0].address,
      contact: contactId
    })
    await phone, email, address
    const contactRes = await api.getEntry('contacts', contactId)
    commit('ADD_CONTACT', contactRes)
    commit('user/SET_MESSAGE', CONTACT_SUCCESS, { root: true })
  },
  async updateContact({ commit }, contact) {
    const contactRes = await api.updateEntry('contacts', contact._id, contact)
    commit('UPDATE_CONTACT', contactRes)
    commit('user/SET_MESSAGE', CONTACT_UPDATE_SUCCESS, { root: true })
  },
  async addNote({ commit }, newNote) {
    const note = await api.createEntry('notes', newNote)
    commit('ADD_CONTACT_NOTE', note)
  },
  async deleteContact({ commit }) {
    commit('user/SET_MESSAGE', CONTACT_DELETE_SUCCESS, { root: true })
    router.push({ name: 'Contacts' })
  },
  async removeNote({ commit }, note) {
    await api.deleteEntry('notes', note._id)
    const updatedContact = await api.getEntry('contacts', note.contact)
    commit('UPDATE_CONTACT', updatedContact)
  },
  async editNote({ commit }, note) {
    const noteRes = await api.updateEntry('notes', note._id, note)
    const updatedContact = await api.getEntry('contacts', noteRes.contact.id)
    commit('UPDATE_CONTACT', updatedContact)
  }
}
const mutations = {
  SET_CONTACTS(s, contacts) {
    s.contacts = contacts
  },
  ADD_CONTACT(s, contact) {
    s.contacts.push(contact)
    router.push({ name: 'Contacts' })
  },
  UPDATE_CONTACT(s, contact) {
    s.contacts = s.contacts.map(c => (c._id === contact._id ? contact : c))
  },
  ADD_CONTACT_NOTE(s, note) {
    const contactId = note.contact._id
    note.contact = note.contact._id
    s.contacts = s.contacts.map(c => {
      return c._id === contactId
        ? Object.assign(c, { notes: [...c.notes, note] })
        : c
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
