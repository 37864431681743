<template>
<div>
  <el-tabs type="card">
    <el-tab-pane label="Contacts">
     <contacts/>
    </el-tab-pane>
    <el-tab-pane label="Groups">
    <contact-groups/>
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
import Contacts from '@/views/contacts/Contacts';
import ContactGroups from '@/views/contacts/ContactGroups';

  export default {
  components: {
    Contacts,
    ContactGroups
  },
};
</script>

