<template>
  <div class="note">
    <div v-if="!isEdit">
      <el-card class="note-card" shadow="never">
        <div>
          <i class="date">{{
            format(Date.parse(note.updatedAt), "MM/DD/YYYY", {
              awareOfUnicodeTokens: true,
            })
          }}</i>
        </div>
        <div>{{ note.text }}</div>
        <el-button type="text" @click="onEdit">{{
          isEdit ? "save" : "edit"
        }}</el-button>
        <el-button class="del-button" type="text" @click="onDelete"
          >delete</el-button
        >
      </el-card>
    </div>
    <div v-else>
      <el-input
        v-model="newNote.text"
        type="textarea"
        placeholder="Add a new note..."
      ></el-input>
      <el-button
        round
        size="small"
        :disabled="newNote.length === 0"
        @click="onSaveEdit"
        >Save Note</el-button
      >
    </div>
  </div>
</template>

<script>
import { format, parse } from "date-fns";
import { CreateNote } from "@/graphql/mutations/CreateContact.graphql";
import { DeleteNote } from "@/graphql/mutations/EditContact.graphql";
import GetContact from "@/graphql/queries/GetContact.graphql";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["note", "contact"],
  data() {
    return {
      format,
      parse,
      isEdit: false,
      newNote: null,
    };
  },
  methods: {
    notification() {
      this.$message({
        message: "Note succesfully updated!",
        type: "success",
        showClose: true,
      });
    },
    deleteNotification() {
      this.$message({
        message: "Note succesfully deleted!",
        type: "success",
        showClose: true,
      });
    },

    onDelete() {
      this.$confirm(
        "This will permanently delete this note. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          await this.$apollo.mutate({
            mutation: DeleteNote,
            variables: {
              id: this.note.id,
            },
            refetchQueries: [
              {
                query: GetContact,
                variables: {
                  id: this.contact,
                },
              },
            ],
          });
          this.deleteNotification();
        })
        .catch(() => {});
    },
    async onSaveEdit() {
      let oldNote = this.note.id;
      await this.$apollo.mutate({
        mutation: CreateNote,
        variables: {
          text: this.newNote.text,
          contact: this.contact,
        },
      });

      await this.$apollo.mutate({
        mutation: DeleteNote,
        variables: {
          id: oldNote,
        },
        refetchQueries: [
          {
            query: GetContact,
            variables: {
              id: this.contact,
            },
          },
        ],
      });
      this.notification();
      this.isEdit = false;
    },
    onEdit() {
      this.newNote = this.note; // assign object to only update on response
      this.isEdit = true;
    },
  },
};
</script>

<style scoped>
.date {
  color: grey;
}
.del-button {
  margin-top: -20px;
}
.note {
  margin-bottom: 20px;
}
.del-button {
  color: red;
}
</style>
<style>
.note-card .el-card__body {
  padding: 0px;
}
</style>


