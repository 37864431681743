<template>
  <div>
    <!-- <el-card style="width: 400"> -->
    <el-table
      ref="multipleTable"
      :data="listData"
      header
      height="300"
      border
      :row-class-name="tableRowClassName"
      style="width: 100%"
      @select="handleSelectionChange"
      @row-click="clickRow"
    >
      <el-table-column type="selection" width="45"></el-table-column>
      <el-table-column prop="name" label="Name" width="250"> </el-table-column>
    </el-table>
  </div>
  <!--   </el-card> -->
</template>

<script>
export default {
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    groupContacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allSelected: false,
      numbers: [],
      multipleTable: [],
    };
  },
  async mounted() {
    this.groupContacts.map((contact) => {
      this.listData.map((r, i) => {
        if (r.id === contact.id) {
          if (this.numbers.indexOf(i) == -1) {
            this.numbers.push(i);
            this.$refs.multipleTable.toggleRowSelection(r);
            this.$emit("select", r, Object.assign({ select: true }));
          } else {
            this.numbers.splice(this.numbers.indexOf(i), 1);
          }
        }
      });
    });
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      let color = "";
      this.numbers.forEach((r) => {
        if (rowIndex === r) {
          color = "warning-row";
        }
      });
      return color;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    clickRow(val) {
      this.$refs.multipleTable.toggleRowSelection(val);
      this.listData.forEach((r, i) => {
        if (r.id == val.id) {
          if (this.numbers.indexOf(i) == -1) {
            this.numbers.push(i);
            this.$emit("select", r, Object.assign({ select: true }));
          } else {
            this.numbers.splice(this.numbers.indexOf(i), 1);
            this.$emit("select", r, Object.assign({ select: false }));
          }
        }
      });
    },
    handleSelectionChange(val, o) {
      this.listData.forEach((r, i) => {
        if (r.id == o.id) {
          if (this.numbers.indexOf(i) == -1) {
            this.numbers.push(i);
            this.$emit("select", r, Object.assign({ select: true }));
          } else {
            this.numbers.splice(this.numbers.indexOf(i), 1);
            this.$emit("select", r, Object.assign({ select: false }));
          }
        }
      });
    },
  },
};
</script>

<style>
.table-label {
  align-self: center;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table__header-wrapper .el-checkbox {
  display: none;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
