<template>
  <div>
    <br />
    <div align="middle" type="flex" justify="center">
      <el-card class="el-card">
        <download-go />
      </el-card>
    </div>
  </div>
</template>
<script>
import DownloadGo from "@/components/auth/DownloadGo";
export default {
  components: {
    DownloadGo,
  },
};
</script>

<style scoped>
.image {
  max-width: 30%;
}
.star-left {
  position: relative;
  width: 200px;
  height: 100px;
  margin: -50px;
  z-index: 1;
  left: -10px;
  top: 10px;
}
.star-right {
  position: relative;
  right: 10px;
  bottom: 0;
  width: 200px;
  height: 100px;
}
.star {
  position: absolute;
  right: -25px;
  top: -40px;
}
.left-star {
  position: absolute;
  right: 260px;
  top: -30px;
}
.card {
  position: relative;
  z-index: 1;
}
.el-card {
  max-width: 500px;
}
.star-size {
  max-width: 50px;
}
.womanimg {
  position: relative;
  width: 60%;
  height: 50%;
}
</style>

