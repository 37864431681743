<template>
  <div>
    <el-row>
      <el-col :sm="{span: 12, offset: 6}">
        <img class="image" src="@/assets/images/oil-logo.png" />
      </el-col>
    </el-row>
    <el-row align="middle" type="flex" justify="center">
      <el-col :sm="{span: 12}" :lg="{span: 8}">
        <div class="star-left">
          <img class="star-size" src="@/assets/images/starLeft.png" alt />
        </div>
        <el-card>
          <br />
          <b slot="header">GREAT!</b>
          <div>
            <p>You're now registred! You can now download the app using the links below and login using the account info that you just created."</p>
          </div>
        </el-card>
        <div class="card">
          <img class="star star-size" src="@/assets/images/starRight.png" alt />
        </div>
      </el-col>
    </el-row>
    <br />
    <el-row align="middle" type="flex" justify="center">
      <el-col :sm="{span: 12}" :lg="{span: 8}">
        <el-card class="box-card">
          <dowload-go-oil />
          <img class="womanimg" src="@/assets/images/woman.png" alt />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import DowloadGoOil from '@/components/auth/DowloadGoOil'
export default {
  components: {
    DowloadGoOil
  }
}
</script>

<style scoped>
.image {
  max-width: 30%;
}
.star-left {
  position: relative;
  width: 100px;
  height: 100px;
  margin: -50px;
  z-index: 1;
  left: 20px;
  top: 50px;
}
.star-right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 100px;
}
.star {
  position: absolute;
  right: -5px;
  top: -50px;
}
.card {
  position: relative;
  z-index: 1;
}
.star-size {
  max-width: 50px;
}
.womanimg {
  position: relative;
  width: 60%;
  height: 50%;
}
</style>

