<template>
  <div v-loading="!use">
    <div v-if="use">
      <div>
        <el-button
          v-if="isMobile()"
          class="backButton"
          round
          plain
          type="info"
          icon="el-icon-back"
          @click="$router.go(-1)"
          >Back</el-button
        >
      </div>
      <el-row align="middle" type="flex" justify="center" :gutter="10">
        <el-col :sm="{ span: 12 }" :lg="{ span: 12 }">
          <el-popover
            class="hidden-xs-only"
            title="Disclamer"
            width="300"
            trigger="hover"
          >
            <h1 slot="reference">
              {{ use.name }}
              <i class="el-icon-info el-icon-right" />
            </h1>
            <p>{{ use.Disclaimer }}</p>
            <a
              class="termslink"
              href="https://droplii.com/terms-of-use/"
              target="_blank"
              >Terms of use</a
            >
          </el-popover>
          <div class="hidden-sm-and-up">
            <h1>
              {{ use.name }}
              <i class="el-icon-info el-icon-right" />
            </h1>
            <!-- <p>{{useItem.Disclaimer}}</p> -->
          </div>
          <el-collapse v-model="activeCollapse">
            <el-collapse-item title="Description" name="1">
              <!-- <div class="justify-text" v-html="getHtml(item.Description)"></div> -->
              <div class="justify-text">
                <vue-markdown :source="use.description"></vue-markdown>
              </div>
              <p></p>
            </el-collapse-item>
            <el-collapse-item title="Highest Voted Recipes" name="2">
              <div v-if="use.recipes.length > 0">
                <div>
                  <div v-for="recipes in recipesInPage" :key="recipes.id">
                    <recipe-card
                      shadow="never"
                      :recipes="recipes"
                      @selectRecipe="handlerecipe(recipes)"
                    />
                    <br />
                  </div>
                  <el-pagination
                    layout="prev, pager, next"
                    :total="total * 3"
                    @current-change="handleCurrentChange"
                  ></el-pagination>
                </div>
              </div>
              <div v-else-if="use.recipes.length <= 0">
                <p>No Recipes yet...</p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import RecipeCard from "@/components/recipes/RecipeCard";
import GetUseDetails from "@/graphql/queries/GetUseDetails.graphql";
import GetUseCategories from "@/graphql/queries/GetUseCategories.graphql";

export default {
  apollo: {
    use: {
      query: GetUseDetails,
      variables() {
        return {
          id: this.$route.params.categoryId,
        };
      },
    },
    usecategories: {
      query: GetUseCategories,
    },
  },
  components: {
    RecipeCard,
    VueMarkdown,
  },
  // eslint-disable-next-line vue/component-definition-name-casing
  name: "selected-use-case",
  props: {
    useItemId: {
      type: String,
      default: null,
    },
    categoryId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeCollapse: ["1", "2"],
      activeTab: "Details",
      selectedRecipe: null,
      currentPage: 1,
      recipes: null,
      useItem: null,
      uses: null,
    };
  },

  computed: {
    total() {
      const recipes = this.use.recipes.filter(this.filterRecipes);
      return recipes.length;
    },
    recipesInPage() {
      if (!this.use.recipes) return [];
      const recipesPerPage = 3;
      const recipes = this.use.recipes.filter(this.filterRecipes);
      //EOM PROTOCOLS BUG HERE
      const startOfRecipes = recipesPerPage * (this.currentPage - 1);
      const endOfRecipes = recipesPerPage * this.currentPage;
      const sorted = recipes
        .slice(startOfRecipes, endOfRecipes)
        .sort((b, a) => {
          return a.upvotes.length - b.upvotes.length;
        });
      return sorted;
    },
  },
  methods: {
    isMobile() {
      if (screen.width <= 769) {
        return true;
      } else {
        return false;
      }
    },
    filterRecipes(recipe) {
      if (!recipe.premiumuse) {
        return recipe;
      }
    },
    handleRecipe(recipes) {
      this.$router.push({ path: "/recipe/" + recipes.id });
      this.$emit("closeDialog"); //TODO check for favorites/votes updating and remove methods
    },
    handleRecipeSelect(recipe) {
      this.selectedRecipe = recipe; //TODO check for favorites/votes updating and remove methods
    },
    handleCurrentChange(selected) {
      this.currentPage = selected;
    },
  },
};
</script>
<style scoped>
.justify-text {
  text-align: justify;
}
.backButton {
  float: left;
  margin-top: -10px;
}
</style>

