<template >
  <div v-if="user">
    <el-row :gutter="15">
      <!-- <el-col :xs="{span: 24}" :sm="{span: 8}">
        <el-card>
          <el-col :span="12">
            <p>Questions?
              <br>Click here to Chat!
            </p>
          </el-col>
        </el-card>
      </el-col>-->
      <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
        <el-card>
          <span slot="header">What would you like to do?</span>
          <router-link class="button-m" tag="el-button" to="/catalog">
            <b-icon class="icon" name="oils2" />View EO Catalog
          </router-link>
          <br />
          <router-link
            v-if="purchases.basic || purchases.mentor || isBasic"
            class="button-m"
            tag="el-button"
            to="/useCatagories"
          >
            <b-icon class="icon" name="recipes2" />View Uses & Recipes
          </router-link>
          <router-link v-else class="button-m" tag="el-button" to="/basic">
            <b-icon class="icon" name="recipes2" />View Uses & Recipes
          </router-link>
          <br />
          <router-link class="button-m" tag="el-button" to="/contacts">
            <b-icon class="icon" name="contacts2" />Manage Contacts
          </router-link>
        </el-card>
      </el-col>
      <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
        <el-card>
          <span slot="header">Builder Actions</span>
          <div v-if="purchases.mentor">
            <!--  <div v-if="subscription && !subscription.isMentor"> -->
            <!--   <h3>{{ mentorCode }}</h3> -->
            <!--  <el-button class="button-m" type="info" plain icon="el-icon-refresh">Change Mentor Code</el-button> -->
            <br />
            <el-button
              class="button-m"
              type="info"
              plain
              icon="el-icon-share"
              @click="dialogVisible = true"
              >Share Free App</el-button
            >
          </div>
          <div v-else>
            <p>
              Builder Subscription: Allows you to add members to your team, and
              share this app for free
            </p>
            <el-button type="info" icon="el-icon-info" @click="onUpgrade"
              >Upgrade now!</el-button
            >
          </div>
        </el-card>
      </el-col>
      <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
        <el-card>
          <div slot="header">
            <span>Favorite Recipes</span>
          </div>
          <div>
            <FavoriteRecipes />
          </div>
        </el-card>
      </el-col>
      <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
        <el-card v-for="post in posts" :key="post.id">
          <div slot="header">
            <span>{{ post.title }}</span>
          </div>
          <p>{{ post.text }}</p>
        </el-card>
      </el-col>
      <!-- <el-col :xs="{span: 24}" :sm="{span: 8}">
        <el-card>
          <div slot="header" class="clearfix">
            <span>Refferal Program</span>
          </div>
          <p>How does it Work?</p>
          <el-button>View Details</el-button>
        </el-card>
      </el-col>-->
    </el-row>
    <el-dialog
      title="Share"
      fullscreen
      :modal="false"
      width="100%"
      :visible.sync="dialogVisible"
    >
      <h1>How would you like to share the app?</h1>
      <br />
      <a :href="`mailto:?subject=Droplii&body=${shareText}`">
        <el-button type="text" plain icon="el-icon-edit-outline"
          >Email</el-button
        >
      </a>
      <br />
      <a :href="`sms:?&body=${shareText}`">
        <el-button type="text" plain icon="el-icon-message"
          >Text Message</el-button
        >
      </a>
      <br />
      <br />
      <el-button plain @click="dialogVisible = false">Done</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FavoriteRecipes from "@/components/recipes/FavoriteRecipes";
import GetPosts from "@/graphql/queries/GetPosts.graphql";

export default {
  components: {
    FavoriteRecipes,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["shadow"],
  apollo: {
    posts: {
      query: GetPosts,
    },
  },
  data() {
    return {
      dialogVisible: false,
      favoriteCollapse: ["1"],
      id: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/profile",
      mentorCode: "user/currentMentorCode",
      subscription: "user/subscription",
      profile: "user/profile",
      favoriteRecipes: "content/favoriteRecipes",
      purchases: "user/purchases",
      isBasic: "user/isBasic",
    }),
    shareText() {
      return `This is an awesome essential oil app that normally costs $14.99 USD but you get it free by following the link below: https://web.pocketoils.com/signupshare?m=${this.$store.state.user.user.profile.id}`;
    },
  },
  methods: {
    onUpgrade() {
      this.$router.push({
        name: "BuilderUpgrade",
      });
    },
    handleCurrentChange(selected) {
      this.currentPage = selected;
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
  /* &:last-child {
    margin-bottom: 0;
  } */
}
.el-col {
  margin-bottom: 20px;
}
.img {
  max-width: 150px;
  height: 150px;
}
.button-m {
  margin: 10px;
}
.icon {
  margin-right: 10px;
}
</style>
