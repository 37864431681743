<template>
  <div>
    <span>Oh, No! Something Went Wrond</span>
  </div>
</template>

<script>
export default {
  created() {
    this.open();
  },
  methods: {
    open() {
      this.$alert("Something Went Wrong, Please try again", "Oh No!", {
        type: "error",
        confirmButtonText: "OK",
        center: true,
        callback: async () => {
          localStorage.clear()
          this.$router.push({ name: "Login" });
        },
      });
    },
  },
};
</script>