<template>
  <div
    :sm="{ span: 12 }"
    :lg="{ span: 8 }"
    :xs="{ span: 21 }"
    align="middle"
    type="flex"
    justify="center"
    :gutter="20"
  >
    <img src="@/assets/images/Droplii_logo-green.png" style="width: 25%" />
    <br />
    <br />
    <br />

    <div>
      <el-card class="el-card">
        <el-steps :space="200" align-center :active="step">
          <el-step title="Sign Up" icon="el-icon-edit"></el-step>
          <el-step title="Droplii" icon="el-icon-check"></el-step>
        </el-steps>
      </el-card>
    </div>
    <br />
    <div>
      <el-card class="el-card">
        <div v-if="step === 0">
          <signup-form @signup="onSignup" />
          <p>
            <router-link :to="{ path: 'login' }"
              >already have an account?</router-link
            >
          </p>
        </div>
        <div v-if="step === 1">
          <download-go :user="user" />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import SignupForm from "@/components/auth/SignupForm";
import { mapGetters } from "vuex";
import DownloadGo from "@/components/auth/DownloadGo";

export default {
  components: {
    SignupForm,
    DownloadGo,
  },
  data() {
    return {
      step: 0,
      user: null,
    };
  },
  computed: {
    ...mapGetters({
      signUp: "user/signUp",
    }),
  },
  mounted() {
    this.cbInstance = window.Chargebee.init({ site: "droplii-test" });
  },
  methods: {
    onSignup(user) {
      this.user = user;
      this.$store.dispatch("user/register", user);
      this.onStep();
    },
    onStep() {
      this.step++;
    },
  },
};
</script>

<style scoped>
.image {
  max-width: 40%;
}
.el-card {
  max-width: 500px;
}
.checkout {
  height: 600px;
  width: 100%;
}
.vl {
  width: 1px; /* Line width */
  background-color: black; /* Line color */
  height: 120px;
  display: inline-block;
}

.appStoreImg {
  width: 90%;
  padding-top: 15px;
}
.step-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
