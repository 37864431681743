<template>
  <div>
    <el-form ref="form" :model="form" label-width="30px">
      <el-form-item>
        <span slot="label">
          <b-icon class="icon-span" name="Username"></b-icon>
        </span>
        <el-input
          v-model="form.email"
          placeholder="Email"
          type="email"
          autocapitalize="off"
          autocorrect="off"
          prop="email"
          @keyup.enter.native="onReset"
        />
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="onReset">Send Email</el-button>
  </div>
</template>

<script>
export default {
  props: {
    isUpgrade: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        email: ''
      }
    }
  },
  methods: {
    onReset() {
      this.$store.dispatch('user/resetPassword', this.form.email)
    }
  }
}
</script>

<style scoped>
.icon-span {
  margin-bottom: -10px;
  padding-right: 50%;
}
</style>
