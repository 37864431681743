<template>
  <div>
    <br />
    <el-col v-for="use in uses" :key="use.id" :xs="12" :sm="12">
      <p class="small-text">{{ use.name }}</p>
      <img :src="use.image.url" class="use-img" @click="$emit('select', use)" />
    </el-col>
  </div>
</template>

<script>
export default {
  props: {
    uses: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="css" scoped>
.use-img {
  max-width: 100%;
  max-height: 150px;
  cursor: pointer;
}
.small-text {
  white-space: nowrap;
  font-size: 100%;
  margin-bottom: -2px;
}
</style>

