<template>
  <div>
 
    <h1>Feedback</h1>
    <el-row align="middle" type="flex" justify="center" :gutter="20">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span
            >We'd love to hear any issues or suggestions so that can we can
            aprove!</span
          >
        </div>
        <div class="div">
          <span>Describe your feedback bellow:</span>
        </div>
        <div class="div">
          <el-input v-model="textarea" type="textarea" :rows="3"> </el-input>
        </div>
        <div>
          <el-button
            type="success"
            round
            :disabled="textarea.length == 0"
            @click="onSubmit"
            >Submit</el-button
          >
        </div>
      </el-card>
    </el-row>
  </div>
</template>


<script>
import CreateFeedback from "@/graphql/mutations/CreateFeedback.graphql";
export default {
  data() {
    return {
      textarea: "",
    };
  },
  methods: {
    notification() {
      this.$message({
        message: "Feedback succesfully send!",
        type: "success",
        showClose: true,
      });
    },
    async onSubmit() {
      await this.$apollo.mutate({
        mutation: CreateFeedback,
        variables: {
          profile: this.$store.state.user.user.profile.id,
          text: this.textarea,
        },
      });
      this.notification();
      await this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style>
.div {
  margin-bottom: 20px;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}
</style>