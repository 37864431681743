<template>
  <div v-loading="!chargebeeAccountLink || !subscriptionStatus">
    <div v-if="chargebeeAccountLink && subscriptionStatus">
      <el-dialog
        title="Inactive Account"
        width="300px"
        :visible.sync="dialogVisible"
      >
        <span>
          <p>Your account has been canceled</p>
          <p>
            If you believe this is a mistake, it's likely that your card has
            expired.
          </p>
          <p>
            Please complete the following steps to reactivate your subscription:
          </p>
          <ol>
            <li>
              Look below make sure all your card information is up-to-date
            </li>
            <li>Press the "Reactivate" Button near the top of this page</li>
          </ol>
          <p>
            if you have any question contact us at
            <a href="mailto:howdy@droplii.com">howdy@droplii.com</a>
          </p>
        </span>
        <el-button @click="dialogVisible = false">Done</el-button>
      </el-dialog>
      <div v-if="isInactive">
        <el-button @click="reactivate">Reactivate</el-button>
        <br />
        <el-button type="text" @click="dialogVisible = true"
          >What does this mean?</el-button
        >
      </div>
      <div>
        <el-button v-if="!isFrameLoading" type="info" @click="onTrouble"
          >Having trouble viewing payment info? Click Here.</el-button
        >
        <iframe
          ref="frame"
          v-loading="isFrameLoading"
          class="frame"
          :src="chargebeeAccountLink"
          frameborder="0"
          @load="handleLoad"
        ></iframe>
      </div>
    </div>
    <el-dialog v-if="troubleDialog" width="300px" :visible.sync="troubleDialog">
      <p>
        Are you seeing:
        <br />
        <b>"Sorry, we couldn't find that page?"</b>
      </p>
      <el-button type="info" @click="onOpenWindow"
        >Click here to reload</el-button
      >
      <p>Still having trouble?</p>
      <p>
        Contact us at
        <a href="mailto:howdy@droplii.com">howdy@droplii.com</a>
      </p>
      <br />
      <el-button plain @click="troubleDialog = false">Done</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      // TODO watch isFrameLoading, when it's false, if there's no cookies: have them click on a link to open settings. actually make this a method.
      dialogVisible: false,
      isInactive: false,
      isFrameLoading: true,
      troubleDialog: false,
      newAccountLink: null,
    };
  },
  computed: {
    ...mapGetters({
      portalSession: "user/portalSession",
    }),
  },
  watch: {
    subscriptionStatus: {
      handler: "checkStatus",
      immediate: true,
    },
  },
  mounted() {
    this.cbInstance = window.Chargebee.init({ site: "droplii-test" });
    this.cbInstance.setPortalSession(() => this.portalSession);
    let cbPortal = this.cbInstance.createChargebeePortal();
    cbPortal.open({
      close() {
        //close callbacks
      },
    });
  },
  methods: {
    checkStatus(sub) {
      if (sub && sub.Status !== 1) {
        this.dialogVisible = true;
        this.isInactive = true;
      } else {
        this.isInactive = false;
      }
    },
    reactivate() {
      this.$store.dispatch("reactivateSubscription");
      this.$router.push({ name: "Account" });
    },
    handleLoad() {
      this.isFrameLoading = false;
    },
    onTrouble() {
      HTTP.get("Chargebee/CustomerPortal/" + this.user.Id).then((response) => {
        this.newAccountLink = response.data;
        this.troubleDialog = true;
      });
    },
    onOpenWindow() {
      // var wnd =
      window.open(this.newAccountLink);
      // setTimeout(() => {
      //   this.$store.dispatch('getChargebeeAccountLink').then(() => {
      //     wnd.close()
      //     location.reload()
      //   })
      // }, 1000)
      return false;
    },
  },
};
</script>

<style>
.frame {
  height: 600px;
  width: 100%;
}
.clickable {
  cursor: pointer;
}
</style>
<style scoped>
.justify-text {
  text-align: justify;
  text-justify: inter-word;
}
</style>
