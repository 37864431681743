<template>
  <div>
    <br />
    <el-form
      ref="editForm"
      :rules="rules"
      :model="editForm"
      label-width="110px"
    >
      <el-form-item label="First Name" prop="firstName">
        <el-input v-model="editForm.firstName" class="input"></el-input>
      </el-form-item>
      <el-form-item label="Last Name" prop="lastName">
        <el-input v-model="editForm.lastName" class="input"></el-input>
      </el-form-item>
      <el-form-item label="Emails">
        <div v-for="(email, index) in editForm.emails" :key="`e-${index}`">
          <el-row>
            <el-col>
              <el-input v-model="email.email" class="input"></el-input>
            </el-col>
            <el-col>
              <el-button
                v-if="editForm.emails.length > 1 && index > 0"
                class="buttom"
                plain
                type="danger"
                icon="el-icon-delete"
                round
                size="small"
                @click="deleteEmail(index)"
                >Delete</el-button
              >
            </el-col>
          </el-row>
        </div>
        <el-button type="info" plain round size="small" @click="addEmail"
          >Add Email</el-button
        >
      </el-form-item>
      <el-form-item label="Phones">
        <div v-for="(phone, index) in editForm.phones" :key="`e-${index}`">
          <el-row>
            <el-col>
              <el-input v-model="phone.number" class="input"></el-input>
            </el-col>
            <el-col>
              <el-button
                v-if="editForm.phones.length > 1 && index > 0"
                class="buttom"
                type="danger"
                plain
                icon="el-icon-delete"
                round
                size="small"
                @click="deletePhone(index)"
                >Delete</el-button
              >
            </el-col>
          </el-row>
        </div>
        <el-button type="info" plain round size="small" @click="addPhone"
          >Add Phone</el-button
        >
      </el-form-item>
      <el-form-item label="Addresses">
        <div v-for="(address, index) in editForm.addresses" :key="`e-${index}`">
          <el-row>
            <el-col>
              <el-input v-model="address.address" class="input"></el-input>
            </el-col>
            <el-col>
              <el-button
                v-if="editForm.addresses.length > 1 && index > 0"
                class="buttom"
                type="danger"
                plain
                icon="el-icon-delete"
                round
                size="small"
                @click="deleteAddress(index)"
                >Delete</el-button
              >
            </el-col>
          </el-row>
        </div>
        <el-button type="info" plain round size="small" @click="addAddress"
          >Add Address</el-button
        >
      </el-form-item>
      <el-button type="danger" plain round size="small" @click="$emit('cancel')"
        >Cancel</el-button
      >
      <el-button
        type="success"
        plain
        round
        size="small"
        @click="onUpdate('editForm')"
        >Save</el-button
      >
    </el-form>
  </div>
</template>
<script>
import GetContact from "@/graphql/queries/GetContact.graphql";
import {
  CreateEmail,
  CreatePhone,
  CreateAddress,
} from "@/graphql/mutations/CreateContact.graphql";
import {
  UpdateContact,
  DeletePhone,
  DeleteEmail,
  DeleteAddress,
} from "@/graphql/mutations/EditContact.graphql";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["form"],
  data() {
    return {
      editForm: null,
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input first name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please input last name",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    form: {
      handler: "setContactForm",
      immediate: true,
    },
  },
  methods: {
    addEmail() {
      this.editForm.emails.push({ email: "" });
    },
    deleteEmail(index) {
      this.editForm.emails.splice(index, 1);
    },
    addPhone() {
      this.editForm.phones.push({ number: "" });
    },
    deletePhone(index) {
      this.editForm.phones.splice(index, 1);
    },
    addAddress() {
      this.editForm.addresses.push({ number: "" });
    },
    deleteAddress(index) {
      this.editForm.addresses.splice(index, 1);
    },
    setContactForm(contact) {
      if (!contact) return;
      this.editForm = Object.assign({}, contact);
    },
    notification() {
      this.$message({
        message: "Contact succesfully updated!",
        type: "success",
        showClose: true,
      });
    },
    async onUpdate(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let oldEmails = this.editForm.emails.filter(Boolean);
          let oldPhones = this.editForm.phones.filter(Boolean);
          let oldAddress = this.editForm.addresses.filter(Boolean);
          let notesIds = this.editForm.notes.map((note) => {
            return note.id;
          });

          //create emails mutations
          var emailsIds = await Promise.all(
            this.editForm.emails.map(async (email) => {
              if (email.email !== "") {
                const createEmails = await this.$apollo.mutate({
                  mutation: CreateEmail,
                  variables: {
                    email: email.email,
                    contact: null,
                  },
                });
                return createEmails.data.createEmail.email.id;
              }
            })
          );

          //create phone mutation
          const phonesIds = await Promise.all(
            this.editForm.phones.map(async (phone) => {
              if (phone.number !== "") {
                const createPhones = await this.$apollo.mutate({
                  mutation: CreatePhone,
                  variables: {
                    number: phone.number,
                    contact: null,
                  },
                });
                return createPhones.data.createPhone.phone.id;
              }
            })
          );

          //create address mutation
          const addressIds = await Promise.all(
            this.editForm.addresses.map(async (address) => {
              if (address.address != "") {
                const createAddresses = await this.$apollo.mutate({
                  mutation: CreateAddress,
                  variables: {
                    address: address.address,
                    contact: null,
                  },
                });
                return createAddresses.data.createAddress.address.id;
              }
            })
          );

          //update contact mutation
          await this.$apollo.mutate({
            mutation: UpdateContact,
            variables: {
              id: this.editForm.id,
              firstName: this.editForm.firstName,
              lastName: this.editForm.lastName,
              emails: emailsIds.filter(Boolean),
              addresses: addressIds.filter(Boolean),
              phones: phonesIds.filter(Boolean),
              notes: notesIds.filter(Boolean),
            },
            refetchQueries: [
              {
                query: GetContact,
                variables: {
                  id: this.editForm.id,
                },
              },
            ],
          });

          //delete phones mutation
          oldPhones.map(async (phone) => {
            if (
              phone.id !== "" &&
              phone.id !== null &&
              phone.id !== undefined
            ) {
              await this.$apollo.mutate({
                mutation: DeletePhone,
                variables: {
                  id: phone.id,
                },
              });
            }
          });

          //delete emails mutation
          oldEmails.map(async (email) => {
            if (
              email.id !== "" &&
              email.id !== null &&
              email.id !== undefined
            ) {
              await this.$apollo.mutate({
                mutation: DeleteEmail,
                variables: {
                  id: email.id,
                },
              });
            }
          });

          //delete address mutation
          oldAddress.map(async (address) => {
            if (
              address.id !== "" &&
              address.id !== null &&
              address.id !== undefined
            ) {
              await this.$apollo.mutate({
                mutation: DeleteAddress,
                variables: {
                  id: address.id,
                },
              });
            }
          });

          this.notification();
          this.$emit("cancel");
        } else {
          this.$alert("Please finish adding contact details.", "Oh no!", {
            confirmButtonText: "OK",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.input {
  width: 250px;
}
</style>

