<template>
  <span>
    <img :src="getIconPath()" :style="`height: ${height}`" @click="dialogVisible = true">
    <el-dialog
      v-if="hasDialog"
      :title="title"
      width="90%"
      :modal="false"
      :visible.sync="dialogVisible"
    >
      <div class="justify-text">
        <p>{{content}}</p>
      </div>
      <el-button plain @click="dialogVisible = false">Done</el-button>
    </el-dialog>
  </span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'oils1'
    },
    height: {
      type: String,
      default: '20px'
    },
    title: {
      type: String,
      default: 'title'
    },
    content: {
      type: String,
      default: 'content'
    },
    hasDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    getIconPath() {
      const name = this.name
      return name ? require('@/assets/icons/' + name + '.png') : ''
    }
  }
}
</script>

<style scoped>
img {
  width: auto;
  cursor: pointer;
  /* padding-right: 10px; */
}
.justify-text {
  text-align: justify;
}
</style>
