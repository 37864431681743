<template>
  <div v-loading="!product">
    <div v-if="product">
      <div>
        <el-button
          v-if="isMobile()"
          class="backButton"
          round
          plain
          type="info"
          icon="el-icon-back"
          @click="$router.go(-1)"
          >Back</el-button
        >
      </div>
      <el-row align="middle" type="flex" justify="center" :gutter="10">
        <el-col :xs="{ span: 20 }" :sm="{ span: 12 }" :lg="{ span: 12 }">
          <div>
            <h1>{{ product.name }}</h1>
          </div>
          <p>
            <i>{{ product.alias }}</i>
          </p>
          <div>
            <b-icon
              v-if="product.isAromatic"
              class="iconBagde"
              name="A"
              height="40px"
              hasDialog
              :title="title.A"
              :content="description.A"
            ></b-icon>
            <b-icon
              v-if="product.isInternal"
              class="iconBagde"
              name="I"
              height="40px"
              hasDialog
              :title="title.I"
              :content="description.I"
            ></b-icon>
            <b-icon
              v-if="product.isTopical"
              class="iconBagde"
              name="T"
              height="40px"
              hasDialog
              :title="title.T"
              :content="description.T"
            ></b-icon>
          </div>
          <el-collapse v-model="activeCollapse">
            <el-collapse-item title="Description" name="1">
              <vue-markdown
                class="justify-text"
                :source="product.description"
              ></vue-markdown>
            </el-collapse-item>
            <el-collapse-item title="Primary Uses" name="2">
              <span
                v-for="use in product.primaryUses"
                :key="use.id"
                class="pad-button"
              >
                <use-button
                  v-if="use"
                  :useApplication="use"
                  @select="handleSelectUse"
                />
              </span>
            </el-collapse-item>
            <el-collapse-item title="Other Uses" name="3">
              <span
                v-for="use in product.otherUses"
                :key="use.id"
                class="pad-button"
              >
                <use-button
                  v-if="use"
                  :useApplication="use"
                  @select="handleSelectUse"
                />
              </span>
            </el-collapse-item>
          </el-collapse>
          <br />
          <el-tabs v-model="activeTab" type="border-card">
            <el-tab-pane label="Details" name="Details">
              <vue-markdown :source="product.details"></vue-markdown>
            </el-tab-pane>
            <el-tab-pane label="Safety" name="Safety">
              <vue-markdown :source="product.safety"></vue-markdown>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { DESCRIPTION, TITLE } from "@/assets/data/oilTypes.js";
import { mapGetters } from "vuex";
import UseButton from "@/components/uses/UseButton";
import VueMarkdown from "vue-markdown";
import GetProductDetail from "@/graphql/queries/GetProductDetail.graphql";

export default {
  components: {
    UseButton,
    VueMarkdown,
  },
  props: {
    itemId: {
      type: String,
      default: null,
    },
  },
  apollo: {
    product: {
      query: GetProductDetail,
      variables() {
        return {
          id: this.$route.params.itemId,
        };
      },
    },
  },

  data() {
    return {
      activeCollapse: ["1", "2"],
      activeTab: "Details",
      description: DESCRIPTION,
      title: TITLE,
      dialogVisible: false,
      product: null,
    };
  },
  computed: {
    ...mapGetters({
      purchases: "user/purchases",
      isBasic: "user/isBasic",
    }),
  },
  methods: {
    isMobile() {
      if (screen.width <= 769) {
        return true;
      } else {
        return false;
      }
    },
    getCategory(UseCategoryId) {
      return this.categories.find((c) => c.Id === UseCategoryId);
    },
    handleSelectUse(product) {
      if (this.purchases.basic || this.purchases.mentor || this.isBasic) {
        this.$router.push({ path: "/uses/" + product.id });
      } else {
        this.$router.push({ path: "/basic" });
      }
    },
  },
};
</script>

<style scoped>
.pad-button {
  padding: 10px;
  float: left;
}
.justify-text {
  text-align: justify;
}
.iconBagde {
  margin: 0 5px;
}
.backButton {
  float: left;
  margin-top: -10px;
}
</style>
