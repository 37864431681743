import axios from 'axios'
// import store from '@/store/store.js'

export const HTTP = axios.create({
  baseURL: `https://pocketoilsapi.azurewebsites.net`
})
// export function setInterceptor() {
//   return HTTP.interceptors.request.use(
//     function(response) {
//       // Do something with response data
//       return response
//     },
//     function(error) {
//       store.commit('ERROR_LOGOUT')
//       return Promise.reject(error)
//     }
//   )
// }
