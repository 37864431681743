import Vue from 'vue'
import App from './App.vue'
import VueAnalytics from 'vue-analytics'
import './plugins/element.js'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import router from './router'
import store from './store/store'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faUser, faHeart, faThumbsUp} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import BIcon from './components/base/BIcon'
import BSearch from './components/base/BSearch'
import BShare from './components/base/BShare'
import VueGoogleApi from 'vue-google-api'
// create the 'options' object
const config = {
    apiKey: 'AIzaSyCTJl7lBJofWh6bbzWcKTvllnLyFmQnnmM',
    clientId: '890724152782-9g32tgdga1od911vmdchjk7iqsa1rl4c.apps.googleusercontent.com',
    discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
    // see all available scopes here: https://developers.google.com/identity/protocols/googlescopes'
    scope: 'https://www.googleapis.com/auth/spreadsheets',
    // works only with `ux_mode: "prompt"`
    refreshToken: true
}
Vue.use(VueGoogleApi, config)
// Firebase
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
const firebaseConfig = {
    apiKey: 'AIzaSyB4DlNOMbSZ40ZDl4g_YXsQcPiTghfca54',
    authDomain: 'stage-web-droplii.firebaseapp.com',
    databaseURL: 'https://stage-web-droplii.firebaseio.com',
    projectId: 'stage-web-droplii',
    storageBucket: 'stage-web-droplii.appspot.com',
    messagingSenderId: '701714655602',
    appId: '1:701714655602:web:edec04cdd67865061e55eb',
    measurementId: 'G-80HYY2H9NP'
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()
// alias
Vue.prototype.$analytics = firebase.analytics()

// Apollo
import VueApollo from 'vue-apollo'
import {ApolloClient} from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {setContext} from 'apollo-link-context'

Vue.component('b-icon', BIcon)
Vue.component('b-search', BSearch)
Vue.component('b-share', BShare)

library.add(faUser)
library.add(faHeart)
library.add(faThumbsUp)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueApollo)

Vue.config.productionTip = false

Vue.use(VueAnalytics, {id: 'UA-160342832-1'})
const httpLink = createHttpLink({
    uri: process.env.VUE_APP_API_URL + '/graphql '
});
const authLink = setContext((_, {headers}) => { // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token')
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`
        }
    }
})
const apolloClient = new ApolloClient({link: authLink.concat(httpLink), cache: new InMemoryCache()})
const apolloProvider = new VueApollo({defaultClient: apolloClient});
new Vue({
    router,
    store,
    apolloProvider,
    render: (h) => h(App)
}).$mount('#app')
