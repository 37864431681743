export const DESCRIPTION = {
  A:
    'Most essential oils are labeled for aromatic use, or by inhalation into the body. Essential oils travel through the nasal cavity where they connect directly with the emotional and limbic control centers of the brain, as well as traveling into the lungs. There are several methods for aromatic use, such as direct inhalation from the bottle or inhalation from oils places on a piece of cloth or cotton. The most convenient method of aromatic use is dispersion into the air using an essential oil diffuser',
  T:
    'Some essential oils are labeled for topical use, or by placing the oils directly onto the skin, enabling them to reach the blood stream very quickly. Essential Oils can be directed to specific parts of the body by applying them to the reflex points on the ears, hands and feet. Avoid topical use unless essential oils have been GC/MS* tested for purity and are labeled for topical use.',
  I:
    'Some essential oils are labeled for internal use as a dietary supplement. These can be placed in drinks, placed under the tongue, or encapsulated. Some oils have a bitter unpleasant taste, while other oils, especially "hot" oils, can cause heartburn and/or burn the mouth and throat. Keep this in mind when selecting a method for internal use. Heartburn can often be avoided with hot oild if they are taken with food. Avoid internal use unless essentail oils have been GC/MS* tested for purity and are labeled for internal use.',
  N:
    'Oils labeled "neat" can be used undiluted. Keep in mind that every individual is different and even the purest essential oils may cause skin sensitivity or other discomfort when used undiluted. Always follow label directions and always use caution when using oils newt for the first time.',
  S:
    'Oils labeled "sensitive" may need to be diluted with a carrier oil such as fractionated coconut oil or extra virgin olive oil to prevent and/or reverse skin sensitivity. Skin sensitivity is irritation and/or burning of the skin due to contact with essential oils. Skin sensitivity generally occurs in children and in individuals with notoriously sensitive skin, but it can also occur unexpectedly and/or after repeat use of the same oil. Each reaction can be different depending on the individual and the oil and will require a different level of dilution to avoid and/or reverse.',
  D:
    'Oils labeled "dilute" are considered "hot" oils and should be heavily diluted with a carrier oil such as fractionated coconut oil or extra virgin olive oil. "Hot" oils used undiluted generally cause severe skin sensitivity when used topically and burn the mouth and throat with used internally. Some "hot" oils can burn the mucus membranes of the nose if inhaled undiluted. Different oils will require different levels of dilution depending on the individual.',
  P:
    'When using essential oils, avoid contact with the eyes, the insides of the ears, and the mucous membranes of the nose. The guidelines listed in this app are intended to apply to the purest essential oils and are general to most individuals, but they are NOT universal. Each individual will respond differently to each essential oil. While it is uncommon to develop allergies to essential oils, it is not unheard of. Always follow the label directions on each individual bottle and use caution when using essential oils for the first time. Always use a "skin patch test" when applying oils topically for the first time. Consult a physician before using essential oils during pregnancy and while breastfeeding.'
}

export const TITLE = {
  A: 'Aromatic',
  T: 'Topical',
  I: 'Internal',
  N: 'Neat',
  S: 'Sensitive',
  D: 'Dilute',
  P: 'Caution'
}
