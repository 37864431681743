<template>
  <div v-loading="!premiumuse">
    <div v-if="premiumuse">
      <div>
        <el-button
          v-if="isMobile()"
          class="backButton"
          round
          plain
          type="info"
          icon="el-icon-back"
          @click="$router.go(-1)"
          >Back</el-button
        >
      </div>
      <el-row align="middle" type="flex" justify="center" :gutter="10">
        <el-col :sm="{ span: 12 }" :lg="{ span: 12 }">
          <el-popover
            class="hidden-xs-only"
            title="Disclamer"
            width="300"
            trigger="hover"
          >
            <h1 slot="reference">
              {{ premiumuse.name }}
              <i class="el-icon-info el-icon-right" />
            </h1>
            <p>{{ premiumuse.Disclaimer }}</p>
            <a
              class="termslink"
              href="https://droplii.com/terms-of-use/"
              target="_blank"
              >Terms of use</a
            >
          </el-popover>
          <div class="hidden-sm-and-up">
            <h1>
              {{ premiumuse.name }}
              <i class="el-icon-info el-icon-right" />
            </h1>
          </div>
          <el-collapse v-model="activeCollapse">
            <el-collapse-item title="Premium Recipes" name="1">
              <div v-if="recipes && premiumuse.recipes.length > 0">
                <div>
                  <div v-for="recipes in recipesInPage" :key="recipes.id">
                    <premium-recipe-card
                      shadow="never"
                      :recipes="recipes"
                      @selectRecipe="handlerecipe(recipes)"
                    />
                    <br />
                  </div>
                  <el-pagination
                    layout="prev, pager, next"
                    :total="premiumuse.recipes.length * 3"
                    @current-change="handleCurrentChange"
                  ></el-pagination>
                </div>
              </div>
              <div v-else-if="premiumuse.recipes.length <= 0">
                <p>No Recipes yet...</p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import GetPremiumUserecipes from "@/graphql/queries/GetPremiumUseRecipes.graphql";
import PremiumRecipeCard from "@/components/recipes/PremiumRecipeCard.vue";
export default {
  apollo: {
    premiumuse: {
      query: GetPremiumUserecipes,
      variables() {
        return {
          id: this.$route.params.premiumId,
        };
      },
    },
  },
  components: {
    PremiumRecipeCard,
  },
  data() {
    return {
      activeCollapse: ["1", "2"],
      selectedRecipe: null,
      currentPage: 1,
      useItem: null,
    };
  },
  computed: {
    recipes() {
      if (!this.premiumuse.recipes) return [];
      return this.premiumuse.recipes;
    },
    recipesInPage() {
      if (!this.premiumuse.recipes) return [];
      const recipesPerPage = 3;
      const startOfRecipes = recipesPerPage * (this.currentPage - 1);
      const endOfRecipes = recipesPerPage * this.currentPage;
      return this.premiumuse.recipes
        .slice(startOfRecipes, endOfRecipes)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    isMobile() {
      if (screen.width <= 769) {
        return true;
      } else {
        return false;
      }
    },
    handleRecipe(recipes) {
      this.$router.push({ path: "/premiumRecipe/" + recipes.id });
      this.$emit("closeDialog");
    },
    handleRecipeSelect(recipe) {
      this.selectedRecipe = recipe;
    },
    handleCurrentChange(selected) {
      this.currentPage = selected;
    },
  },
};
</script>
<style scoped>
.backButton {
  float: left;
  margin-top: -10px;
}
</style>
