<template>
  <div v-if="contactgroup">
    <div>
      <el-button
        v-if="isMobile()"
        class="backButton"
        round
        plain
        type="info"
        icon="el-icon-back"
        @click="$router.go(-1)"
        >Back</el-button
      >
      <h1>{{ contactgroup.name }}</h1>
    </div>

    <el-collapse v-if="contactgroup" v-model="activeCollapse">
      <el-collapse-item title="Group Info" name="1">
        <el-row align="middle" type="flex" justify="center" :gutter="20">
          <el-col :sm="{ span: 12 }" :lg="{ span: 8 }">
            <view-group-form
              v-if="!isEdit"
              :viewForm="contactgroup"
              @edit="isEdit = !isEdit"
            />
            <edit-group-form
              v-if="isEdit"
              :form="contactgroup"
              @cancel="isEdit = !isEdit"
            />
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import EditGroupForm from "@/components/contacts/EditGroupForm";
import ViewGroupForm from "@/components/contacts/ViewGroupForm";
import GetContactGroup from "@/graphql/queries/GetContactGroup.graphql";

export default {
  components: {
    EditGroupForm,
    ViewGroupForm,
  },
  apollo: {
    contactgroup: {
      query: GetContactGroup,
      variables() {
        return {
          id: this.$route.params.groupId,
        };
      },
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["groupId"],
  data() {
    return {
      activeCollapse: ["1"],
      isEdit: false,
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 769) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.picture {
  border-radius: 50%;
  max-width: 200px;
}
.backButton {
  float: left;
  margin-top: -10px;
}
</style>