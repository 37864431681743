<template>
  <div>
    <el-form ref="form" :rules="rules" :model="form" label-width="50px">
      <el-form-item prop="Password">
        <span slot="label">
          <b-icon class="icon-span" name="Password"></b-icon>
        </span>
        <el-input
          v-model="form.password"
          placeholder="New Password"
          type="password"
          :auto-complete="'off'"
          @keyup.enter.native="onLogin"
        />
      </el-form-item>
      <el-form-item prop="RepeatPassword">
        <span slot="label">
          <b-icon class="icon-span" name="Password"></b-icon>
        </span>
        <el-input
          v-model="form.passwordConfirmation"
          placeholder="Confirm New Password"
          type="password"
          :auto-complete="'off'"
          @keyup.enter.native="onUpdate"
        />
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="onUpdate">Update Password</el-button>
  </div>
</template>

<script>
export default {
  props: {
    isUpgrade: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else if (value !== this.$refs.form.model.password) {
        callback(new Error('Passwords do not match'))
      } else {
        callback()
      }
    }
    return {
      form: {
        password: '',
        passwordConfirmation: ''
      },
      rules: {
        password: [
          { required: true, message: 'Please input password', trigger: 'blur' }
        ],
        passwordConfirmation: [{ validator: validatePass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    onUpdate() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const code = this.$route.query.code
          const resetPassword = Object.assign({ code }, this.form)
          this.$store.dispatch('user/confirmResetPassword', resetPassword)
        } else {
          this.$alert('Please make sure your passwords match.', 'Oh no!', {
            confirmButtonText: 'OK'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
