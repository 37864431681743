<template>
  <el-row>
    <div v-if="catalogIsDone || usesIsDone">
      <el-cascader
        ref="cascader"
        v-loading="!catalogIsDone || !usesIsDone"
        size="large"
        clearable
        placeholder="Search Catalog and Recipes"
        :options="options"
        filterable
        expand-trigger="hover"
        @change="handleSelect"
      ></el-cascader>
    </div>
  </el-row>
</template>
<script>
import GetProductCategories from "@/graphql/queries/GetProductCategories.graphql";
import GetUseCategories from "@/graphql/queries/GetUseCategories.graphql";
import { mapGetters } from "vuex";

export default {
  apollo: {
    productcategories: {
      query: GetProductCategories,
    },
    usecategories: {
      query: GetUseCategories,
    },
  },
  data() {
    return {
      input: "",
      catalogIsDone: false,
      usesIsDone: false,
    };
  },
  computed: {
    ...mapGetters({
      purchases: "user/purchases",
      isBasic: "user/isBasic",
    }),
    options() {
      let categoriesName = this.productcategories.map((categories) => {
        return {
          value: categories.name,
          label: categories.name,
          children: categories.products.map(this.makeChild),
        };
      });
      let usesName = [
        {
          value: "Uses",
          label: "Uses & Recipes",
          children: this.usecategories.map(this.makeChildInChild),
        },
      ];
      let loadedOptions = usesName.concat(categoriesName);
      return loadedOptions;
    },
  },
  watch: {
    usecategories: {
      handler: "loadingData",
    },
  },
  methods: {
    handleSelect(value) {
      if (value[0] === "Oils")
        this.$router.push({ path: "/catalog/oils/" + value[1] });
      else if (value[0] === "Blends")
        this.$router.push({ path: "/catalog/blends/" + value[1] });
      else if (value[0] === "Supplements")
        this.$router.push({ path: "/catalog/supplements/" + value[1] });
      else if (value[0] === "Uses") {
        if (typeof value[2] !== "undefined") {
          if (this.purchases.mentor || this.purchases.basic || this.isBasic) {
            this.$router.push({ path: "/uses/" + value[2] });
          } else {
            this.$router.push({ name: "BasicUpgrade" });
          }
        } else {
          if (this.purchases.mentor || this.purchases.basic || this.isBasic) {
            this.$router.push({ path: "/uses/" + value[1] });
          } else {
            this.$router.push({ name: "BasicUpgrade" });
          }
        }
      }
    },
    makeChild(catalog) {
      return {
        value: catalog.id,
        label: catalog.name,
      };
    },
    makeChildInChild(use) {
      if (use.name === "All Uses/Ailments") {
        const usesMaped = this.usecategories.flatMap((c) => c.uses);
        usesMaped.sort((a, b) => a.name.localeCompare(b.name));
        const removedDuplicates = [...new Set(usesMaped)];
        return {
          value: use.name,
          label: use.name,
          children: removedDuplicates.map(this.makeChild),
        };
      } else {
        const usesMaped = use.uses;
        const removedDuplicates = [...new Set(usesMaped)];
        return {
          value: use.name,
          label: use.name,
          children: removedDuplicates.map(this.makeChild),
        };
      }
    },
    loadingData() {
      if (this.usecategories && this.productcategories) {
        this.usesIsDone = true;
        this.catalogIsDone = true;
      }
    },
  },
};
</script>

<style>
.el-cascader {
  width: 340px;
  max-width: 100%;
}
.el-autocomplete {
  width: 25%;
  min-width: 280px;
}
.mobile-search-bar {
  width: 100%;
}
</style>
