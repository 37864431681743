<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-form-item label="First Name" prop="firstName">
        <el-input v-model="form.firstName"></el-input>
      </el-form-item>
      <el-form-item label="Last Name" prop="lastName">
        <el-input v-model="form.lastName"></el-input>
      </el-form-item>
      <el-form-item
        v-for="(phone, index) in form.phones"
        :key="`phone${index}`"
        :label="'Phone ' + (index + 1)"
        :prop="'phones.' + index + '.number'"
      >
        <el-input v-model="phone.number" type="tel"></el-input>
        <el-button v-if="index > 0" type="text" icon="el-icon-minus"
          >Phone</el-button
        >
      </el-form-item>
      <!-- <el-form-item size="mini">
        <el-button icon="el-icon-plus" type="text">Phone</el-button>
      </el-form-item>-->
      <el-form-item
        v-for="(email, index) in form.emails"
        :key="`email${index}`"
        :label="'Email ' + (index + 1)"
        :prop="'emails.' + index + '.email'"
      >
        <el-input v-model="email.email" type="email"></el-input>
        <el-button v-if="index > 0" type="text" icon="el-icon-minus"
          >Email</el-button
        >
      </el-form-item>
      <!-- <el-form-item size="mini">
        <el-button icon="el-icon-plus" type="text" size="mini">Email</el-button>
      </el-form-item>-->
      <el-form-item
        v-for="(address, index) in form.addresses"
        :key="`address${index}`"
        :label="'Address ' + (index + 1)"
        :prop="'addresses.' + index + '.address'"
      >
        <el-input v-model="address.address"></el-input>
        <el-button v-if="index > 0" type="text" icon="el-icon-minus"
          >Address</el-button
        >
      </el-form-item>
      <!-- <el-form-item size="mini">
        <el-button icon="el-icon-plus" type="text" size="mini">Address</el-button>
      </el-form-item>-->
      <el-form-item
        v-for="(notes, index) in form.notes"
        :key="`notes${index}`"
        :label="'Note'"
        :prop="'notes.' + index + '.note'"
      >
        <el-input v-model="notes.note" type="textarea"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.push({ name: 'Contacts' })"
          >Cancel</el-button
        >
        <el-button
          v-loading="isSubmitting"
          type="primary"
          @click="onCreate('form')"
          >Create</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CreatePhone,
  CreateEmail,
  CreateAddress,
  CreateContact,
  CreateNote,
} from "@/graphql/mutations/CreateContact.graphql";
import GetContacts from "@/graphql/queries/GetContacts.graphql";

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSkipping: true,
      isSubmitting: false,
      form: {
        firstName: "",
        lastName: "",
        phones: [
          {
            number: "",
          },
        ],
        emails: [
          {
            email: "",
          },
        ],
        addresses: [
          {
            address: "",
          },
        ],
        notes: [
          {
            note: "",
          },
        ],
      },
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input first name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please input last name",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["contacts"]),
  },
  watch: {
    id: {
      handler: "findContact",
      immediate: true,
    },
  },
  methods: {
    notification() {
      this.$message({
        message: "Contact succesfully created!",
        type: "success",
        showClose: true,
      });
    },
    async onCreate(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          //create emails mutations
          var emailsIds = await Promise.all(
            this.form.emails.map(async (email) => {
              if (email.email !== "") {
                const createEmails = await this.$apollo.mutate({
                  mutation: CreateEmail,
                  variables: {
                    email: email.email,
                    contact: null,
                  },
                });
                return createEmails.data.createEmail.email.id;
              }
            })
          );

          //create phone mutation
          const phonesIds = await Promise.all(
            this.form.phones.map(async (phone) => {
              if (phone.number !== "") {
                const createPhones = await this.$apollo.mutate({
                  mutation: CreatePhone,
                  variables: {
                    number: phone.number,
                    contact: null,
                  },
                });
                return createPhones.data.createPhone.phone.id;
              }
            })
          );

          //create notes mutation
          const notesIds = await Promise.all(
            this.form.notes.map(async (note) => {
              if (note.note !== "") {
                const createNotes = await this.$apollo.mutate({
                  mutation: CreateNote,
                  variables: {
                    text: note.note,
                    contact: null,
                  },
                });
                return createNotes.data.createNote.note.id;
              }
            })
          );

          //create address mutation
          const addressIds = await Promise.all(
            this.form.addresses.map(async (address) => {
              if (address.address != "") {
                const createAddresses = await this.$apollo.mutate({
                  mutation: CreateAddress,
                  variables: {
                    address: address.address,
                    contact: null,
                  },
                });
                return createAddresses.data.createAddress.address.id;
              }
            })
          );
          //create contact
          await this.$apollo.mutate({
            mutation: CreateContact,
            variables: {
              profile: this.$store.state.user.user.profile.id,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              emails: emailsIds.filter(Boolean),
              phones: phonesIds.filter(Boolean),
              notes: notesIds.filter(Boolean),
              addresses: addressIds.filter(Boolean),
            },
            refetchQueries: [
              {
                query: GetContacts,
                variables: {
                  id: this.$store.state.user.user.profile.id,
                },
              },
            ],
          });
          this.notification();
          await this.$router.push({ name: "Contacts" });
        } else {
          this.$alert("Please finish adding contact details.", "Oh no!", {
            confirmButtonText: "OK",
          });
          return false;
        }
      });
    },
    findContact(id) {
      if (!id) return;
      const contact = this.contacts.filter((c) => c.id === id);
      this.form = contact;
    },
  },
};
</script>

<style>
</style>

