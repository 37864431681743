<template>
  <el-card :shadow="shadow" class="recipe-card" @click.native="onSelectRecipe">
    <div slot="header">
      <span class="title">{{ recipes.name }}</span>
      <span style="float: right; padding: 0 0" type="text"
        >{{ recipes.upvotes ? recipes.upvotes.length : 0 }} upvotes</span
      >
    </div>
    <span>
      <!-- <i>{{recipe.Ingredients.length}} Ingredients</i> -->
    </span>
    <br />
    <span>{{ recipes.instructions.substring(0, 95) }}...</span>
  </el-card>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["recipes", "shadow"],
  methods: {
    onSelectRecipe() {
      this.$router.push({
        name: "Recipe",
        params: { recipeId: this.recipes.id },
      });
    },
  },
};
</script>

<style scoped>
.recipe-card {
  cursor: pointer;
}
.title {
  font-weight: 525;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
