<template>
  <div>
    <span>Oh, No!</span>
  </div>
</template>

<script>
export default {
  created() {
    this.open();
  },
  methods: {
    open() {
      this.$alert("Purchase Canceled!", "Oh No", {
        type: "error",
        confirmButtonText: "OK",
        center: true,
        callback: async () => {
          await this.$store.dispatch("user/getPurchases");
          this.$router.push({ name: "Home" });
        },
      });
    },
  },
};
</script>