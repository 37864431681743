<template>
  <div>
    <el-row :gutter="20">
      <el-col
        v-for="category in productcategories"
        :key="category.id"
        class="pad-card"
        :xs="{ span: 12 }"
        :sm="{ span: 8 }"
      >
        <router-link :to="category.slug" append>
          <el-card shadow="always">
            <a>{{ category.name }}</a>
          </el-card>
        </router-link>
      </el-col>
      <!-- <el-col class="pad-card" :xs="{ span: 12}" :sm="{ span: 6}">
        <a @click="dialogVisible = true">
          <el-card shadow="always">
            <strong>
              <a>Dilution Chart</a>
            </strong>
          </el-card>
        </a>
      </el-col>-->
    </el-row>
    <el-row :gutter="20">
      <el-col class="pad-card" :xs="{ span: 24 }" :sm="{ span: 12 }">
        <el-card shadow="never">
          <div slot="header">Application methods</div>
          <el-col :span="8">
            <b-icon
              name="A"
              hasDialog
              :content="description.A"
              :title="title.A"
              height="30px"
            ></b-icon>
            <br />Aromatic
          </el-col>
          <el-col :span="8">
            <b-icon
              name="T"
              hasDialog
              :content="description.T"
              :title="title.T"
              height="30px"
            ></b-icon>
            <br />Topical
          </el-col>
          <el-col :span="8">
            <b-icon
              name="I"
              hasDialog
              :content="description.I"
              :title="title.I"
              height="30px"
            ></b-icon>
            <br />Internal
          </el-col>
        </el-card>
      </el-col>
      <el-col class="pad-card" :xs="{ span: 24 }" :sm="{ span: 12 }">
        <el-card shadow="never">
          <div slot="header">Skin Sensitivity</div>
          <el-col :span="8">
            <b-icon
              name="N"
              hasDialog
              :content="description.N"
              :title="title.N"
              height="30px"
            ></b-icon>
            <br />Neat
          </el-col>
          <el-col :span="8">
            <b-icon
              name="S"
              hasDialog
              :content="description.S"
              :title="title.S"
              height="30px"
            ></b-icon>
            <br />Sensitive
          </el-col>
          <el-col :span="8">
            <b-icon
              name="D"
              hasDialog
              :content="description.D"
              :title="title.D"
              height="30px"
            ></b-icon>
            <br />Dilute
          </el-col>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="Dilution Chart"
      fullscreen
      :modal="false"
      :visible.sync="dialogVisible"
    >
      <span>The Dilution chart will be included here.</span>
      <br />
      <br />
      <el-button plain type="primary" @click="dialogVisible = false"
        >Close</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import { DESCRIPTION, TITLE } from "@/assets/data/oilTypes.js";
import GetProductCategories from "@/graphql/queries/GetProductCategories.graphql";
export default {
  apollo: {
    productcategories: {
      query: GetProductCategories,
    },
  },
  data() {
    return {
      description: DESCRIPTION,
      title: TITLE,
      dialogVisible: false,
    };
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
  /* &:last-child {
    margin-bottom: 0;
  } */
}
.el-col {
  margin-bottom: 20px;
}
a {
  cursor: pointer;
}
</style>