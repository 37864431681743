<template>
  <div>
    <el-card shadow="never">
      <p class="justify-text">{{ testimonial.text }}</p>
      <el-button
        :type="userHasLiked ? 'primary' : ''"
        :plain="!userHasLiked"
        size="mini"
        round
        @click="like"
      >
        <font-awesome-icon icon="thumbs-up" class="thumbsUp-icon" />
        {{ testimonial.Rating }} likes
      </el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["testimonial"],
  computed: {
    userHasLiked() {
      return this.testimonial.likes.some(
        (likes) => likes.id === this.$store.state.user.user.profile.id
      );
    },
  },
  methods: {
    like() {
      this.$emit("likeTestimonial", this.testimonial);
    },
  },
};
</script>

<style scoped>
.thumbsUp-icon {
  margin-right: 5px;
}
.justify-text {
  text-align: justify;
}
</style>
