<template>
  <div>
    <el-row>
      <el-col :sm="{span: 12, offset: 6}">
        <img class="image" src="@/assets/images/Droplii_logo-green.png">
        <br>
        <br>
        <br>
      </el-col>
    </el-row>
    <el-row align="middle" type="flex" justify="center" :gutter="20">
      <el-col :sm="{span: 12}" :lg="{span: 8}">
        <el-card class="box-card">
          <div slot="header">
            <span>
              <strong>Update Password</strong>
            </span>
          </div>
          <confirm-reset-password-form/>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ConfirmResetPasswordForm from '@/components/auth/ConfirmResetPasswordForm'
export default {
  components: {
    ConfirmResetPasswordForm
  }
}
</script>

<style scoped>
.image {
  max-width: 40%;
}
</style>
