<template>
  <div>
    <el-row :gutter="20">
      <el-col :class="mobileSelected" :sm="{ span: 6 }">
        <list :listData="uses" @select="handleSelectRecipeCategory" />
      </el-col>
      <el-col :sm="{ span: 18 }">
        <router-view :categoryId="selectedUseId" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import List from "@/components/List";
import GetUseCategories from "@/graphql/queries/GetUseCategories.graphql";

export default {
  name: "Uses",
  components: {
    List,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["useId"],
  apollo: {
    usecategories: {
      query: GetUseCategories,
    },
  },
  data() {
    return {};
  },
  computed: {
    mobileSelected() {
      const isSelected = !!this.selectedUseId;
      return { "hidden-xs-only": isSelected };
    },
    selectedUseId() {
      return this.$route.params.categoryId;
    },
    uses() {
      if (!this.usecategories) return [];
      let uses = null;
      let selectedUSe = this.usecategories.find((c) => c.id === this.useId);
      if (selectedUSe.name === "All Uses/Ailments") {
        const usesMaped = this.usecategories.flatMap((c) => c.uses);
        const removedDuplicates = [...new Set(usesMaped)];
        uses = removedDuplicates;
      } else {
        const usesMaped = this.usecategories.find(
          (c) => c.id === this.useId
        ).uses;
        const removedDuplicates = [...new Set(usesMaped)];
        uses = removedDuplicates;
      }
      return uses;
    },
  },
  methods: {
    handleSelectRecipeCategory(item) {
      this.$router.push({ path: `/useCatagories/${this.useId}/${item.id}` });
    },
  },
};
</script>
<style scoped>
