import router from '@/router'
export const ERROR = {
  title: 'Oh no!',
  options: {
    type: 'warning',
    confirmButtonText: 'OK',
    closeOnClickModal: true,
    center: true
  }
}
export const LOGIN_ERROR = {
  title: 'Oh no!',
  text: 'Looks like you entered a wrong Email or Password',
  options: {
    type: 'warning',
    confirmButtonText: 'OK',
    closeOnClickModal: true,
    center: true
  }
}

export const PROVIDER_LOGIN_ERROR = {
  title: 'Oh no!',
  text: `Looks like there's no associated account with that provider. Try logging with email.`,
  options: {
    type: 'warning',
    confirmButtonText: 'OK',
    closeOnClickModal: true,
    center: true
  }
}

export const SIGNUP_ERROR = {
  title: 'Oh no!',
  text:
    'It looks like an account with that email already exists. Try logging in if you have already created an account or contact us at howdy@droplii.com',
  options: {
    type: 'warning',
    confirmButtonText: 'Log in',
    cancelButtonText: 'Try again',
    closeOnClickModal: true,
    center: true,
    callback: res => {
      if (res === 'confirm')
        router.push({
          name: 'Login'
        })
    }
  }
}

export let CONTACT_SUCCESS = {
  message: 'Contact succesfully created!',
  type: 'success',
  showClose: true
}
export let CONTACT_UPDATE_SUCCESS = {
  message: 'Contact succesfully updated!',
  type: 'success',
  showClose: true
}

export let CONTACT_DELETE_SUCCESS = {
  message: 'Contact succesfully deleted!',
  type: 'success',
  showClose: true
}

export let TESTIMONIAL_SUBMIT_SUCCESS = {
  message: 'Testimonial Submitted!',
  type: 'success',
  showClose: true
}

export let USER_UPDATED = {
  message: 'User Info Updated!',
  type: 'success',
  showClose: true
}

export let PASSWORD_UPDATED = {
  message: 'Password Updated!',
  type: 'success',
  showClose: true
}
export let PASSWORD_RESET_SENT = {
  message: 'Email sent, please check your email.',
  type: 'success',
  showClose: true
}
export let PASSWORD_RESET_NOT_SENT = {
  message: 'Password not updated, please try again.',
  type: 'warning',
  showClose: true
}

export const PASSWORD_UPDATE_ERROR = {
  title: 'Oh no!',
  text:
    'Current password was incorrect. Please check your password or contact at us howdy@droplii.com',
  options: {
    type: 'warning',
    confirmButtonText: 'OK',
    closeOnClickModal: true,
    center: true
  }
}
