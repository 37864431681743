<template>
  <div>
    <div v-if="purchases.mentor">
      <el-col :sm="{ span: 4 }" :class="mobileSelected">
        <el-button round icon="el-icon-plus" @click="navToCreateGroup('manual')"
          >Group</el-button
        >
        <br />
        <list
          v-loading="!contactgroups"
          :listData="groupListWithName"
          @select="handleSelectedGroup"
        />
        <br />
        <!-- <export-to-c-s-v-button class="m-50" :data="contacts">Download Contacts</export-to-c-s-v-button> -->
      </el-col>
      <el-col ref="useCaseCol" :sm="{ span: 19 }" :offset="1">
        <router-view :groupId="selectedGroupId" />
      </el-col>
      <el-dialog :visible.sync="createDialog" width="90%">
        <h1>How would you like to add a group?</h1>
        <br />
        <el-button type="primary" @click="navToCreateGroup('manual')"
          >Manually</el-button
        >
        <!-- <el-button type="primary">From Mentor Code</el-button> -->
        <!-- <el-button type="primary">From CSV</el-button> -->
      </el-dialog>
    </div>
    <div v-else>
      <empty-contact />
    </div>
  </div>
</template>

<script>
import List from "@/components/List";
import EmptyContact from "@/components/contacts/EmptyContact";
//import ExportToCSVButton from "@/components/contacts/ExportToCSVButton";
import GetContacts from "@/graphql/queries/GetContacts.graphql";
import { mapGetters } from "vuex";

export default {
  components: {
    List,
    EmptyContact,
    // ExportToCSVButton,
  },
  apollo: {
    contactgroups: {
      query: GetContacts,
      variables() {
        return {
          id: this.$store.state.user.user.profile.id,
        };
      },
      skip() {
        return !this.$store.state.user.user;
      },
      update(data) {
        return data.profile.contactgroups;
      },
    },
  },
  data() {
    return {
      createDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      purchases: "user/purchases",
    }),
    groupListWithName() {
      if (!this.contactgroups) return [];
      return this.contactgroups.map((c) => Object.assign(c, { name: c.name }));
    },
    selectedGroupId() {
      return this.$route.params.groupId;
    },
    mobileSelected() {
      const isSelected = this.selectedGroupId;
      return { "hidden-xs-only": isSelected };
    },
  },
  methods: {
    handleSelectedGroup(group) {
      this.$router.push({ path: "/group/" + group.id }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    navToCreateGroup(createType) {
      this.$router.push({
        name: "Create Group",
        params: { type: createType },
      });
    },
  },
};
</script>

<style scoped>
.m-50 {
  margin-top: 50px;
}
</style>
