<template>
  <div>
    <br />
    <el-form
      ref="editForm"
      :rules="rules"
      :model="editForm"
      label-width="100px"
    >
      <el-form-item label="Group Name" prop="name">
        <el-input v-model="editForm.name" class="input"></el-input>
      </el-form-item>
      <el-form-item label="Contacts">
        <el-col>
          <check-list
            ref="list"
            v-loading="!contacts"
            :listData="contactListWithName"
            :groupContacts="groupContactListWithName"
            @select="handleSelectionChange"
          />
        </el-col>
      </el-form-item>

      <el-button type="danger" plain round size="small" @click="$emit('cancel')"
        >Cancel</el-button
      >
      <el-button
        type="success"
        plain
        round
        size="small"
        @click="onUpdate('editForm')"
        >Save</el-button
      >
    </el-form>
  </div>
</template>
<script>
import GetContactGroup from "@/graphql/queries/GetContactGroup.graphql";

import {
  UpdateContactGroupName,
  UpdateContactGroupContacts,
} from "@/graphql/mutations/EditContactGroup.graphql";
import CheckList from "@/components/CheckList";
import GetContacts from "@/graphql/queries/GetContacts.graphql";

export default {
  components: {
    CheckList,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["form"],
  apollo: {
    contacts: {
      query: GetContacts,
      variables() {
        return {
          id: this.$store.state.user.user.profile.id,
        };
      },
      skip() {
        return !this.$store.state.user.user;
      },
      update(data) {
        return data.profile.contacts;
      },
    },
  },
  data() {
    return {
      editForm: null,
      numbers: [],
      contactsIds: [],
      rules: {
        name: [
          {
            required: true,
            message: "Please input group name",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    contactListWithName() {
      if (!this.contacts) return [];
      const names = this.contacts.map((c) =>
        Object.assign(c, { name: c.firstName + " " + c.lastName, id: c.id })
      );
      var sortedNames = names.sort((a, b) => {
        if (a.name < b.name) return -1;
        return a.name > b.name ? 1 : 0;
      });
      return sortedNames;
    },
    groupContactListWithName() {
      if (!this.editForm.contacts) return [];
      const names = this.editForm.contacts.map((c) =>
        Object.assign(c, { name: c.firstName + " " + c.lastName, id: c.id })
      );
      var sortedNames = names.sort((a, b) => {
        if (a.name < b.name) return -1;
        return a.name > b.name ? 1 : 0;
      });
      return sortedNames;
    },
  },
  watch: {
    form: {
      handler: "setContactForm",
      immediate: true,
    },
  },

  methods: {
    handleSelectionChange(val, selected) {
      if (selected.select) {
        this.contactsIds.push(val.id);
      } else {
        const index = this.contactsIds.indexOf(val.id);
        if (index > -1) {
          this.contactsIds.splice(index, 1);
        }
      }
    },
    setContactForm(contactgroup) {
      if (!contactgroup) return;
      this.editForm = Object.assign({}, contactgroup);
    },
    notification() {
      this.$message({
        message: "Group succesfully updated!",
        type: "success",
        showClose: true,
      });
    },
    async onUpdate(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.contactsIds.length > 0) {
            //update group Name mutation
            await this.$apollo.mutate({
              mutation: UpdateContactGroupName,
              variables: {
                id: this.editForm.id,
                name: this.editForm.name,
              },
            });
            //upgrade group contacts mutation
            await this.$apollo.mutate({
              mutation: UpdateContactGroupContacts,
              variables: {
                id: this.editForm.id,
                contacts: this.contactsIds,
              },
              refetchQueries: [
                {
                  query: GetContactGroup,
                  variables: {
                    id: this.editForm.id,
                  },
                },
              ],
            });
            this.notification();
            this.$emit("cancel");
          } else {
            this.$alert("please select at least one contact", "Oh no!", {
              confirmButtonText: "OK",
            });
            return false;
          }
        } else {
          this.$alert("Please finish adding group details.", "Oh no!", {
            confirmButtonText: "OK",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.input {
  width: 250px;
}
</style>

