<template>
  <div v-loading="!user">
    <div class="div">
      <img class="image" src="@/assets/images/ilustration.webp" />
    </div>
    <div class="div">
      <p class="text">
        Get lifetime access to thousands of user input recipes and protocols as
        well as all of the Essential Oil Magic protocols.
      </p>
    </div>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :clientReferenceId="userId"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (loading = v)"
      />
      <el-button class="button" plain @click="submit"> Buy Now! </el-button>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { mapGetters } from "vuex";
export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_TOKEN;
    return {
      loading: false,
      lineItems: [
        {
          price: "price_1J8CvMAPwQt4sTQ5BiVyNx5I", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: process.env.VUE_APP_URL + "/sucess",
      cancelURL: process.env.VUE_APP_URL + "/cancel",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    userId() {
      return this.user ? this.user.id : "";
    },
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<style>
.div {
  margin-bottom: 20px;
}
.text {
  white-space: pre;
  font-size: 17px;
  color: darkgray;
}
.button {
  background-color: white;
  color: #9dd49f;
  border: 2px solid #9dd49f;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
}
</style>
