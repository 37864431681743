<template>
  <div v-loading="!profile">
    <div v-if="profile">
      <h1>Profile</h1>
      <div v-if="profile.picture">
        <el-avatar v-if="!isLoading" :size="110" :src="form.picture.url">
        </el-avatar>
        <el-avatar v-else :size="110">
          <i class="el-icon-loading"></i>
        </el-avatar>
        <div>
          <el-button round plain type="info" @click="onButtonClick">
            <i left class="el-icon-camera"> </i>
            Upload Photo
          </el-button>
          <input
            ref="uploader"
            class="hiden"
            type="file"
            accept="image/*"
            @change="onUpload"
          />
        </div>
      </div>
      <div v-else>
        <div v-if="!isSelecting">
          <font-awesome-icon icon="user" class="user-icon" />
        </div>
        <div v-else>
          <el-avatar :size="110">
            <i class="el-icon-loading"></i>
          </el-avatar>
        </div>
        <div>
          <el-button round plain type="info" @click="onButtonClick">
            <i left class="el-icon-camera"> </i>
            Upload Photo
          </el-button>
          <input
            ref="uploader"
            class="hiden"
            type="file"
            accept="image/*"
            @change="onUpload"
          />
        </div>
      </div>
      <h1>{{ profile.name }}</h1>
      <p v-if="profile.mentor">
        <img class="link" src="@/assets/icons/ic_link.png" />
        <a class="builderLink" @click="onBuilderClick">{{
          profile.mentor.name
        }}</a>
      </p>

      <p v-if="profile.IsMentor">
        Code:
        <a>
          <strong>{{ user.MentorCode }}</strong>
        </a>
      </p>
      <!-- <p>My Mentor: {{mentor.FirstName}}</p> -->
      <!-- <p>Interests: {{user.UserInterests.map(i => i.UseCategoryName).join(', ')}}</p> -->
      <el-row align="middle" type="flex" justify="center" :gutter="20">
        <el-col :sm="{ span: 12 }" :lg="{ span: 8 }">
          <el-tabs v-model="activeTab" type="border-card">
            <el-tab-pane label="General" name="General">
              <el-form ref="form" :model="form" label-width="90px">
                <el-form-item label="Name">
                  <el-input v-model="form.name" placeholder="Name"></el-input>
                </el-form-item>
                <el-form-item label="Phone">
                  <el-input
                    v-model="form.phoneNumber"
                    placeholder="You don't have a phone yet"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Bio">
                  <el-input
                    v-model="form.bio"
                    :rows="3"
                    type="textarea"
                    placeholder="You don't have a Bio yet"
                  >
                  </el-input>
                </el-form-item>
                <el-button :disabled="!hasFormChanged" @click="submitUpdateUser"
                  >Update</el-button
                >
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="Login" name="Login">
              <el-form v-if="!changePassword" label-width="90px">
                <el-form-item label="Email">{{ form.user.email }}</el-form-item>
                <el-form-item label="Password">
                  <el-button @click="changePassword = true"
                    >Change Password</el-button
                  >
                </el-form-item>
              </el-form>
              <el-form v-else ref="loginForm" :model="form" label-width="150px">
                <el-form-item label="Current Password">
                  <el-input
                    v-model="loginForm.password"
                    type="password"
                  ></el-input>
                </el-form-item>
                <el-form-item label="New Password">
                  <el-input
                    v-model="loginForm.newPassword"
                    type="password"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Confirm">
                  <el-input
                    v-model="loginForm.confirmPassword"
                    type="password"
                  ></el-input>
                </el-form-item>
                <el-button @click="changePassword = false">Cancel</el-button>
                <el-button
                  :disabled="disablePasswordSubmit"
                  type="primary"
                  @click="submitUpdatePassword"
                  >Save New Password</el-button
                >
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import UpdateProfile from "@/graphql/mutations/UpdateProfile.graphql";
import { api } from "@/api/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isSelecting: false,
      selectedFile: null,
      isLoading: false,
      fit: "scale-down",
      activeTab: "General",
      progress: null,
      error: null,
      form: {
        picture: {
          url: "",
        },
        name: "",
        phoneNumber: "",
        user: {
          email: "",
        },
        bio: "",
      },
      changePassword: false,
      loginForm: {
        identifier: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      profile: "user/profile",
    }),
    hasFormChanged() {
      return JSON.stringify(this.profile) !== JSON.stringify(this.form);
    },
    disablePasswordSubmit() {
      return (
        this.loginForm.newPassword !== this.loginForm.confirmPassword ||
        this.loginForm.newPassword === ""
      );
    },
  },
  watch: {
    profile: {
      handler: "setFormUser",
      immediate: true,
    },
  },
  methods: {
    onBuilderClick() {
      this.$router.push({ path: "builder" });
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    async onUpload(event) {
      this.isLoading = true;
      this.isSelecting = true;
      const URL = "/upload/";
      let data = new FormData();
      data.append("files", event.target.files[0]);
      data.append("ref", "profile");
      data.append("refId", this.profile.id);
      data.append("field", "picture");
      try {
        await api.axios.post(URL, data);
        this.isLoading = false;
        this.isSelecting = false;
        this.uploadNotification();
        //refetch profile and save in store, to show new picture
        this.$store.dispatch("user/getProfile");
      } catch (error) {
        this.isLoading = false;
        this.isSelecting = false;
      }
    },
    setFormUser(profile) {
      if (!profile) return;
      this.form = Object.assign({}, profile);
    },
    notification() {
      this.$message({
        message: "Profile succesfully updated!",
        type: "success",
        showClose: true,
      });
    },
    uploadNotification() {
      this.$message({
        message: "Photo succesfully updated!",
        type: "success",
        showClose: true,
      });
    },
    updateNofication() {
      this.$message({
        message: "Password succesfully updated!",
        type: "success",
        showClose: true,
      });
    },
    onError() {
      this.$alert("Please check fields and try again.", "Warning", {
        confirmButtonText: "OK",
        type: "warning",
      });
    },
    async submitUpdateUser() {
      await this.$apollo.mutate({
        mutation: UpdateProfile,
        variables: {
          id: this.profile.id,
          name: this.form.name,
          phoneNumber: this.form.phoneNumber,
          bio: this.form.bio,
        },
      });
      await this.$store.dispatch("user/getProfile");
      this.notification();
    },
    async submitUpdatePassword() {
      let email = this.profile.user.email;
      this.loginForm.identifier = email;
      try {
        await api.axios.put("/updatePassword", this.loginForm);
        this.changePassword = false;
        this.loginForm.password = "";
        this.loginForm.newPassword = "";
        this.loginForm.confirmPassword = "";
        this.updateNofication();
      } catch (error) {
        this.onError();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/element-variables.scss";

.link {
  margin-right: 10px;
  height: 20px;
}

.builderLink {
  cursor: pointer;
}
.user-icon {
  padding: 10px;
  margin: 10px;
  font-size: 50px;
  color: $--color-primary;
}
.hiden {
  height: 0%;
  width: 0%;
}
.header {
  height: 10px;
  width: 150px;
}
.v-icon--left {
  margin-right: 8px;
}
</style>
