<template>
  <div v-if="contact">
    <div>
      <el-button
        v-if="isMobile()"
        class="backButton"
        round
        plain
        type="info"
        icon="el-icon-back"
        @click="$router.go(-1)"
        >Back</el-button
      >
      <h1>{{ contact.firstName + " " + contact.lastName }}</h1>
    </div>
    <el-collapse v-if="contact" v-model="activeCollapse">
      <el-collapse-item title="Contact Info" name="1">
        <el-row align="middle" type="flex" justify="center" :gutter="20">
          <el-col :sm="{ span: 12 }" :lg="{ span: 8 }">
            <view-contact-form
              v-if="!isEdit"
              :viewForm="contact"
              @edit="isEdit = !isEdit"
              @addNote="onAddNote"
              @deleteNote="onDeleteNote"
              @delete="onDelete"
              @editNote="onEditNote"
            />
            <edit-contact-form
              v-if="isEdit"
              :form="contact"
              @cancel="isEdit = !isEdit"
              @save="onSaveContact"
            />
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import EditContactForm from "@/components/contacts/EditContactForm";
import ViewContactForm from "@/components/contacts/ViewContactForm";
import GetContact from "@/graphql/queries/GetContact.graphql";

export default {
  components: {
    EditContactForm,
    ViewContactForm,
  },
  apollo: {
    contact: {
      query: GetContact,
      variables() {
        return {
          id: this.$route.params.contactId,
        };
      },
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["contactId"],
  data() {
    return {
      activeCollapse: ["1"],
      isEdit: false,
      mobile: false,
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 769) {
        return true;
      } else {
        return false;
      }
    },
    onSaveContact(contact) {
      this.$store.dispatch("contacts/updateContact", contact);
      this.isEdit = !this.isEdit;
    },
    onDelete(contactId) {
      this.$store.dispatch("contacts/deleteContact", contactId);
      this.$emit("deselectContact");
    },
    onDeleteNote(note) {
      this.$store.dispatch("contacts/removeNote", note);
    },
    onAddNote(newNote) {
      const noteWithContact = Object.assign(
        { text: newNote },
        {
          contact: this.contact._id,
        }
      );
      this.$store.dispatch("contacts/addNote", noteWithContact);
    },
    onEditNote(note) {
      this.$store.dispatch("contacts/editNote", note);
    },
  },
};
</script>

<style scoped>
.picture {
  border-radius: 50%;
  max-width: 200px;
}
.backButton {
  float: left;
  margin-top: -10px;
}
</style>
