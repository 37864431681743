<template>
  <div>
    <br>
    <p>It looks like this account hasn't been activated yet</p>
    <p>Please select a payment plan from:</p>
    <p>
      <strong>
        <a href="https://droplii.com/start-pricing/">Droplii Pricing</a>
      </strong>
    </p>
    <!-- <iframe class="checkout" :src="checkoutLink" frameborder="0"></iframe> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['checkoutLink'])
  }
}
</script>

<style>
.checkout {
  height: 600px;
  width: 100%;
}
</style>
