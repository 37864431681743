<template >
  <div v-loading="!recipe">
    <el-row align="middle" type="flex" justify="center" :gutter="10">
      <el-col :sm="{ span: 12 }" :lg="{ span: 10 }">
        <div v-if="recipe && recipe.name">
          <h1>{{ recipe.name }}</h1>
          <br />
          <br />
          <el-button
            class="button-m"
            type="info"
            plain
            icon="el-icon-share"
            @click="onShare"
            >Share</el-button
          >
          <el-button
            class="button-m"
            :plain="!userHasFavorited"
            :type="userHasFavorited ? 'primary' : ''"
            @click="onFavoriteOrRemove"
          >
            <font-awesome-icon icon="heart" class="icon" />Favorite
          </el-button>
          <br />
          <br />
          <el-collapse v-model="activeCollapse">
            <el-collapse-item title="Ingredients" name="1">
              <p v-for="i in recipe.ingredients" :key="i.id">
                {{ i.quantity }} {{ i.unit }} {{ i.name }}
              </p>
            </el-collapse-item>
            <el-collapse-item
              v-if="recipe.suggestedDuration"
              title="suggested Duration"
              name="2"
            >
              <div class="justify-text">
                <p>{{ recipe.suggestedDuration }}</p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              v-if="recipe.instructions"
              title="Instructions"
              name="3"
            >
              <div class="justify-text">
                <p>{{ recipe.instructions }}</p>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Testimonials" name="4">
              <div
                v-for="testimonial in recipeTestimonials"
                :key="testimonial.id"
              >
                <testimonial
                  :testimonial="testimonial"
                  @likeTestimonial="onLikeTestimonial"
                />
              </div>
              <el-button
                v-if="!isAddTestimonial"
                @click="isAddTestimonial = true"
                >Add Testimonials</el-button
              >
              <create-testimonial
                v-else
                :profile="profile"
                :recipe="recipe"
                @cancel="isAddTestimonial = false"
              />
            </el-collapse-item>
          </el-collapse>
          <b-share
            header="How would you like to share?"
            :title="recipe.Title"
            :text="createTextForRecipe()"
            :dialogVisible="shareDialog"
            @closeDialog="shareDialog = false"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Testimonial from "@/components/recipes/Testimonial";
import CreateTestimonial from "@/components/recipes/CreateTestimonial";
import { mapGetters } from "vuex";
import GetRecipeQuery from "@/graphql/queries/GetRecipe.graphql";
import AddFavoriteMutation from "@/graphql/mutations/AddFavoriteMutation.graphql";
import RemoveFavoriteMutation from "@/graphql/mutations/RemoveFavoriteMutation.graphql";
import GetFavoriteRecipesQuery from "@/graphql/queries/GetFavoriteRecipes.graphql";
import AddLikeMutation from "@/graphql/mutations/AddLikeMutation.graphql";
import RemoveLikeMutation from "@/graphql/mutations/RemoveLikeMutation.graphql";

export default {
  components: {
    Testimonial,
    CreateTestimonial,
  },
  props: {
    recipeId: {
      type: String,
      default: null,
    },
    length: {
      type: String,
      default: null,
    },
  },

  apollo: {
    recipe: {
      query: GetRecipeQuery,
      variables() {
        return {
          id: this.$route.params.premiumId,
          profile: this.$store.state.user.profile.id,
        };
      },
      skip() {
        return !this.$store.state.user.profile;
      },
    },
  },
  data() {
    return {
      activeCollapse: ["1", "2", "3", "4"],
      shareDialog: false,
      isAddTestimonial: false,
      recipe: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: "user/profile",
      favoriteRecipes: "content/favoriteRecipes",
      upvoteRecipes: "content/upvoteRecipes",
      purchases: "user/purchases",
    }),
    userHasFavorited() {
      return this.recipe.favorites.some(
        (favorites) => favorites.id == this.profile.id
      );
    },
    recipeTestimonials() {
      return Object.assign(
        this.recipe.testimonials,
        this.recipe.myTestimonials
      );
    },
  },
  methods: {
    getCategory(UseCategoryId) {
      return this.categories.find((c) => c.Id === UseCategoryId);
    },
    createTextForRecipe() {
      const title = this.recipe.Title + " %0D%0A %0D%0A";
      const instructions = this.recipe.Instructions + "%0D%0A %0D%0A";
      const droplii = "Shared with Droplii.com";
      return title + instructions + droplii;
    },
    onShare() {
      if (this.purchases.mentor || this.purchases.share) {
        if (navigator.share) {
          const shareText =
            `Ingredients:\n${this.recipe.ingredients.map(
              (ingredient) =>
                ingredient.quantity +
                " " +
                ingredient.unit +
                " " +
                ingredient.name +
                " "
            )}` + `\n\nInstructions:\n${this.recipe.instructions}`;
          console.log(shareText);
          navigator.share({
            title: this.recipe.name,
            text: shareText,
            url: "https://developer.mozilla.org",
          });
        } else {
          this.shareDialog = true;
        }
      } else {
        this.$router.push({ path: "/share" });
      }
    },
    onFavoriteOrRemove() {
      if (this.userHasFavorited) {
        this.$apollo.mutate({
          mutation: RemoveFavoriteMutation,
          variables: {
            recipe: this.$route.params.premiumId,
            profile: this.$store.state.user.user.profile.id,
          },
          refetchQueries: [
            {
              query: GetFavoriteRecipesQuery,
              variables: {
                profileId: this.$store.state.user.user.profile.id,
              },
            },
          ],
        });
      } else {
        this.$apollo.mutate({
          mutation: AddFavoriteMutation,
          variables: {
            recipe: this.$route.params.premiumId,
            profile: this.$store.state.user.user.profile.id,
          },
          refetchQueries: [
            {
              query: GetFavoriteRecipesQuery,
              variables: {
                profileId: this.$store.state.user.user.profile.id,
              },
            },
          ],
        });
      }
    },
    onLikeTestimonial(testimonial) {
      var liked = testimonial.likes.some(
        (likes) => likes.id === this.$store.state.user.user.profile.id
      );
      if (liked) {
        this.$apollo.mutate({
          mutation: RemoveLikeMutation,
          variables: {
            testimonial: testimonial.id,
            profile: this.$store.state.user.user.profile.id,
          },
          refetchQueries: [
            {
              query: GetRecipeQuery,
              variables: {
                id: this.$route.params.premiumId,
                profile: this.$store.state.user.user.profile.id,
              },
            },
          ],
        });
      } else {
        this.$apollo.mutate({
          mutation: AddLikeMutation,
          variables: {
            testimonial: testimonial.id,
            profile: this.$store.state.user.user.profile.id,
          },
          refetchQueries: [
            {
              query: GetRecipeQuery,
              variables: {
                id: this.$route.params.premiumId,
                profile: this.$store.state.user.user.profile.id,
              },
            },
          ],
        });
      }
    },
  },
};
</script>

<style scoped>
.aa-button {
  padding: 20px;
  margin: 20px;
}
.icon {
  margin-right: 5px;
}
.justify-text {
  text-align: justify;
}
.button-m {
  margin-top: 10px;
}
</style>