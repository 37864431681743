<template>
  <div>
    <div v-for="note in sortedNotes" :key="note.id">
      <note :note="note" :contact="contact" />
    </div>
    <el-input
      v-model="newNote"
      type="textarea"
      placeholder="Add a new note..."
    ></el-input>
    <el-button
      round
      size="small"
      :disabled="newNote.length === 0"
      @click="addNote"
      >Save Note</el-button
    >
  </div>
</template>

<script>
import Note from "@/components/contacts/Note";
import { CreateNote } from "@/graphql/mutations/CreateContact.graphql";
import GetContact from "@/graphql/queries/GetContact.graphql";

export default {
  components: {
    Note,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["notes", "contact"],
  data() {
    return {
      newNote: "",
    };
  },
  computed: {
    sortedNotes() {
      return this.notes
        .slice()
        .sort((a, b) => a.updatedAt.localeCompare(b.updatedAt));
    },
  },
  methods: {
    notification() {
      this.$message({
        message: "Note succesfully created!",
        type: "success",
        showClose: true,
      });
    },
    async addNote() {
      await this.$apollo.mutate({
        mutation: CreateNote,
        variables: {
          text: this.newNote,
          contact: this.contact,
        },
        refetchQueries: [
          {
            query: GetContact,
            variables: {
              id: this.contact,
            },
          },
        ],
      });
      this.newNote = "";
      this.notification();
    },
  },
};
</script>
