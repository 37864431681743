<template>
  <div>
    <br />
    <el-form ref="form" :model="form" :rules="rules" label-width="50px">
      <el-form-item prop="email">
        <span slot="label">
          <b-icon name="Username"></b-icon>
        </span>
        <el-input
          v-model="form.email"
          type="email"
          placeholder="Email"
          autocapitalize="off"
          autocorrect="off"
        ></el-input>
      </el-form-item>
      <el-form-item prop="username">
        <span slot="label">
          <b-icon name="Username"></b-icon>
        </span>
        <el-input
          v-model="form.username"
          type="email"
          autocapitalize="off"
          autocorrect="off"
          placeholder="Confirm Email"
        />
      </el-form-item>
      <el-form-item prop="password">
        <span slot="label">
          <b-icon name="Password"></b-icon>
        </span>
        <el-input
          v-model="form.password"
          placeholder="Password"
          type="password"
          :auto-complete="'off'"
        />
      </el-form-item>
      <el-form-item required>
        <span slot="label">
          <b-icon name="Name"></b-icon>
        </span>
        <el-form-item prop="name">
          <el-input v-model="form.profileToCreate.name" placeholder="Name" />
        </el-form-item>
      </el-form-item>
      <el-form-item prop="phoneNumber">
        <span slot="label">
          <b-icon name="Phone"></b-icon>
        </span>
        <el-input v-model="form.profileToCreate.phoneNumber" type="tel" placeholder="Phone Number" />
      </el-form-item>
      <el-form-item prop="country">
        <span slot="label">
          <b-icon name="Name"></b-icon>
        </span>
        <el-select v-model="form.country" filterable placeholder="Country">
          <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="redemptioncode">
        <span slot="label">
          <b-icon name="Name"></b-icon>
        </span>
        <el-input v-model="form.redemptioncode" placeholder="Promo/Redemption Code" />
      </el-form-item>
      <el-checkbox v-model="checked">
        <p>
          I agree to the
          <a
            class="termslink"
            href="https://droplii.com/terms-of-use/"
            target="_blank"
          >Privacy Policy & Terms of Use</a>
        </p>
      </el-checkbox>
    </el-form>
    <el-button type="primary" @click="onSignupOil('form')">Sign Up</el-button>
  </div>
</template>

<script>
import { api } from '../../api/api'
import router from '@/router'
import axios from 'axios'
export default {
  data() {
    return {
      listaDatos: [],
      list: [],
      states: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antarctica',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burma',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo, Democratic Republic',
        'Congo, Republic of the',
        'Costa Rica',
        "Cote d'Ivoire",
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Greenland',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Korea, North',
        'Korea, South',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Mongolia',
        'Morocco',
        'Monaco',
        'Mozambique',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russia',
        'Rwanda',
        'Samoa',
        'San Marino',
        ' Sao Tome',
        'Saudi Arabia',
        'Senegal',
        'Serbia and Montenegro',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe'
      ],
      checked: false,
      form: {
        email: '',
        username: '',
        password: '',
        redemptioncode: '',
        purchases: ['5e167e715ecc98001934ca82'],
        profileToCreate: {
          name: '',
          phoneNumber: '',
          country: ''
        }
      },
      rules: {
        email: [
          { required: true, message: 'Please input email', trigger: 'blur' }
        ],
        username: [
          { required: true, message: 'Please confirm email', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please input password', trigger: 'blur' }
        ],
        redemptioncode: [
          {
            required: true,
            message: 'Please input Redemption Code',
            trigger: 'blur'
          }
        ],
        country: [
          {
            required: true,
            message: 'Please input your Country',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    this.list = this.states.map(item => {
      return { value: `${item}`, label: `${item}` }
    })
  },
  methods: {
    onSignupOil(formName) {
      this.$refs[formName].validate(valid => {
        if (
          valid &&
          this.checked &&
          this.form.redemptioncode == 'A788X2' &&
          this.form.profileToCreate.name != '' &&
          this.form.profileToCreate.phoneNumber != ''
        ) {
          const email = this.form.email
          const country = this.form.country
          const name = this.form.profileToCreate.name
          const phoneNumber = this.form.profileToCreate.phoneNumber
          const data = [[email], [name], [country], [phoneNumber]]
          const userToCreate = this.form
          api.axios
            .post(
              'https://api.pocketoils.com/auth/local/register',
              userToCreate
            )
            .then(router.push({ name: 'ConfirmOilMagic' }))
          axios
            .post('https://stage-web-droplii.firebaseio.com/myArray.json', data)
            .then(
              this.$alert('Your account was successfully created', 'Great', {
                confirmButtonText: 'OK'
              })
            )
        } else if (this.form.profileToCreate.name == '') {
          this.$alert('Please finish adding signup details.', 'Oh no!', {
            confirmButtonText: 'OK'
          })
          return false
        } else if (this.form.profileToCreate.phoneNumber == '') {
          this.$alert('Please finish adding signup details.', 'Oh no!', {
            confirmButtonText: 'OK'
          })
          return false
        } else if (!valid) {
          this.$alert('Please finish adding signup details.', 'Oh no!', {
            confirmButtonText: 'OK'
          })
          return false
        } else if (this.form.redemptioncode != 'A788X2') {
          this.$alert(
            'The Code is not correct please enter the code again',
            'Oh no!',
            {
              confirmButtonText: 'OK'
            }
          )
        } else if (!this.checked) {
          this.$alert(
            'Please check "I agree to the Privacy Policy & Terms of Use."',
            'Oh no!',
            {
              confirmButtonText: 'OK'
            }
          )
        }
      })
    }
  }
}
</script>

<style scoped>
.terms {
  font-size: 10px;
  color: grey;
}
</style>
