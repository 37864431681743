<template>
  <div>
    <div v-if="type === 'manual'">
      <el-row align="middle" type="flex" justify="center" :gutter="20">
        <el-col :sm="{ span: 12 }" :lg="{ span: 8 }">
          <manual-create-edit-contact />
        </el-col>
      </el-row>
    </div>
    <div v-if="type === 'mentor'"></div>
    <div v-if="type === 'csv'"></div>
  </div>
</template>

<script>
import ManualCreateEditContact from "@/components/contacts/ManualCreateEditContact";
export default {
  components: {
    ManualCreateEditContact,
  },
  props: {
    type: {
      type: String,
      default: "manual",
    },
  },
};
</script>

<style>
</style>
