<template>
  <div>
    <router-link tag="el-button" :to="{path: '/account'}">Account & Billing</router-link>
    <br>
    <br>
    <router-link tag="el-button" :to="{path: '/profile'}">Profile</router-link>
    <br>
    <br>
    <el-button @click="$store.commit('LOGOUT')">Logout</el-button>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>
