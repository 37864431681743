<template>
  <div>
    <div
      :sm="{ span: 12 }"
      :lg="{ span: 8 }"
      :xs="{ span: 21 }"
      align="middle"
      type="flex"
      justify="center"
      :gutter="20"
    >
      <img src="@/assets/images/Droplii_logo-green.png" style="width: 25%" />
      <br />
      <br />
      <br />
      <el-card class="el-card">
        <div slot="header">
          <span>
            <strong>Login</strong>
          </span>
        </div>
        <login-form />
        <!--   <div>
            <p class="horizontal-line">or login with</p>
            <button class="loginBtn loginBtn--facebook" @click="fbLogin">Login with Facebook</button>
          </div> -->
        <p>
          <router-link :to="{ path: 'signup' }"
            >don't have an account?</router-link
          >
          <br />
          <router-link :to="{ path: 'reset' }"
            >forgot your password?</router-link
          >
        </p>
      </el-card>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm";
import { api } from "../../api/api.js";

export default {
  components: {
    LoginForm,
  },
  methods: {
    fbLogin() {
      window.location = api.getProviderAuthenticationUrl("facebook");
    },
  },
};
</script>

<style scoped>
/* .image {
  max-width: 20%;

} */
.el-card {
  max-width: 500px;
  align-self: center;
}
</style>
<style>
.horizontal-line {
  overflow: hidden;
  text-align: center;
}

.horizontal-line:before,
.horizontal-line:after {
  background-color: rgba(26, 26, 26, 0.1);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.horizontal-line:before {
  right: 0.5em;
  margin-left: -50%;
}

.horizontal-line:after {
  left: 0.5em;
  margin-right: -50%;
}
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}
</style>

