import {api} from '../../api/api.js'
// import Strapi from 'strapi-sdk-javascript'
// export const api = new Strapi('http://localhost:1337')
import * as m from '@/assets/data/messages'
import {Loading, Message, MessageBox} from 'element-ui'
import router from '@/router'
import moment from 'moment'

const getSelectedSubscription = () => {
    return {
        plan: sessionStorage.getItem('subscription') || 'mentor-subscription',
        mentor: sessionStorage.getItem('mentor')
    }
}

const makeError = text => Object.assign({}, m.ERROR, {text})
const state = {
    user: null,
    profile: null,
    builder: null,
    purchases: {},
    basic: null,
    oilmagic: null,
    mentor: null,
    share: null,
    loading: null,
    signUp: {
        step: 0,
        checkoutLink: null
    },
    subscription: {
        plan: null,
        status: null
    },
    userMentor: null
}

const getters = {
    purchases: s => s.purchases,
    isBasic: s => (s.user ? s.user.purchases.some(purchase => purchase['identifier'] === 'basic') : false),
    isOilMagic: s => (s.user ? s.user.purchases.some(purchase => purchase['identifier'] === 'oilmagic') : false),
    isAuth: s => !!s.user,
    user: s => s.user,
    profile: s => s.profile,
    builder: s => s.builder,
    signUp: s => s.signUp,
    profileId: s => (s.user ? s.user.profile._id : null),
    subscription: s => {
        if (!s.subscription || !s.subscription.plan) 
            return null


        


        const isMentor = s.subscription.plan.includes('mentor')
        const hasPaymentInfo = isMentor || s.subscription.plan.includes('lifetime')
        const status = s.subscription.status
        return {isMentor, status, hasPaymentInfo}
    },
    portalSession: s => {
        if (!s.subscription) 
            return null


        


        return new Promise(resolve => resolve(s.subscription.portalSession))
    },
    currentMentorCode: s => {
        if (!s.userMentor) 
            return null


        


        return s.userMentor.mentorcodes.reduce((a, b) => {
            return a.createdAt > b.createdAt ? a : b
        }).code
    }
}
const actions = {
    async login(
        {
            commit,
            dispatch
        },
        user
    ) {
        try {
            const {jwt} = await api.login(user.email, user.password)
            localStorage.setItem('token', jwt)
            await dispatch('getAllLogin')

        } catch (error) {
            const errorMsg = makeError("Username or Password invalid")
            commit('STOP_LOADING')
            commit('SET_MESSAGE_BOX', errorMsg)
        }
    },
    async authenticateProvider(
        {commit, dispatch}
    ) {
        try {
            // once we have the subscription back, uncomment all following code.
            // const login = await api.authenticateProvider('facebook') // this depends on it not being in history mode.
            await api.authenticateProvider('facebook')
            // // this depends on it not being in history mode.

            // if (login.user.profile && login.user.subscription) {
            dispatch('getAllLogin')
            // return
            // }

            // const me = await api.request('get', 'users/me')
            // commit('SET_USER', me)
            // commit('SET_PROFILE', me.profile)
            // if (getSelectedSubscription().plan === 'shared') {
            // dispatch('finishSubscription')
            // router.push({ name: 'Signup Share', query: { isProvider: true } })
            // } else {
            // router.push({ name: 'Signup', query: { isProvider: true } })
            // dispatch('finishSubscription')
            // }
        } catch (error) {
            router.push({name: 'Login'})
            commit('SET_MESSAGE_BOX', m.SIGNUP_ERROR)
        }
        // TODO will have to create profile for user.
        // and subscription.
    },

    async getAllLogin(
        {commit, dispatch}
    ) {
        commit('START_LOADING')
        try {
            const user = await api.request('get', 'users/me')
            commit('SET_USER', user)
            dispatch('getProfile')
            dispatch('getPurchases')
        } catch (error) {
            commit('LOGOUT')
        }
    },

    async getUser({commit}) {
        try {
            const user = await api.request('get', 'users/me')
            commit('SET_USER', user)
            /*  dispatch('content/getContent', null, {root: true}) */
        } catch (error) {
            console.log(error)
        }
    },
    async getPurchases() {
        const date = moment().format();
        try {
            const {data} = await api.axios.get("/revenuecat/purchases");
            if (data.mentor.expires_date > date) {
                const obj = Object.assign({mentor: data.mentor})
                state.mentor = obj
            }
            if (data.share.expires_date > date) {
                const obj = Object.assign({share: data.share})
                state.share = obj
            }
            if (data.oilmagic.expires_date > date) {
                const obj = Object.assign({oilmagic: data.oilmagic})
                state.oilmagic = obj
            }
            if (data.basic) {
                const obj = Object.assign({basic: data.basic})
                state.basic = obj
            }
            const purchases = Object.assign({}, state.basic, state.share, state.oilmagic, state.mentor)
            state.purchases = Object.assign(purchases)
        } catch (error) {
            console.log("No Purchases")
        }
    },
    async getProfile({commit}) {
        const profile = await api.request('get', 'profiles/' + state.user.profile.id)
        commit('SET_PROFILE', profile)
        if (profile.mentor) {
            const builder = await api.request('get', 'profiles/' + profile.mentor.id)
            commit('SET_BUILDER', builder)
        }
        commit('STOP_LOADING')
        /* commit('content/SET_UPVOTES_RECIPES', profile.upvotes, {root: true})  */
    },
    // create registerShared() function
    // send register request to server
    async registerShared({
        commit
    }, user) {
        await api.axios.post('/register ', user)
        commit('NEXT_SIGNUP_STEP')
    },

    async registerOil(
        {
            commit,
            dispatch
        },
        user
    ) {
        try {
            const regOil = await api.register(user.email, user.username, user.password)
            commit('SET_USER', regOil.user)
            const profile = Object.assign({}, user, {userId: regOil.user.id})
            await dispatch('createProfileOil', profile)
        } catch (error) {
            const errorMsg = makeError(error.message)
            commit('SET_MESSAGE_BOX', errorMsg)
        }
    },
    async createProfileOil({
        commit
    }, user) {
        const baseProfile = {
            name: user.name,
            phoneNumber: user.phoneNumber,
            user: user.userId
        }
        const profile = Object.assign(baseProfile)
        const profileResponse = await api.createEntry('profiles', profile)
        commit('SET_PROFILE', profileResponse)
    },
    async register({
        commit
    }, user) {
        try {
            const regResponse = await api.axios.post('/auth/local/register', user)
            commit('SET_USER', regResponse.user)
            // const profile = Object.assign({}, user, { userId: regResponse.user.id })
            // await dispatch('createProfile', profile)
            // const subscription = await dispatch('createSubscription', profile)
            // commit('SET_SUBSCRIPTION', subscription)
            commit('NEXT_SIGNUP_STEP')
            // return subscription.hostedPage
            // return JSON.parse(JSON.stringify(profileResponse.hostedPage))
        } catch (error) {
            let errorString = ""
            if (Array.isArray(error.response.data.message)) {
                errorString = error.response.data.message[0]['messages'][0]['message']
            } else {
                errorString = error.response.data.message
            }
            const errorMsg = makeError(errorString)
            commit('SET_MESSAGE_BOX', errorMsg)
            throw error
        }
    },
    async providerRegister(
        {
            commit,
            dispatch,
            state
        },
        form
    ) {
        const profile = Object.assign({}, getters.profile, form)
        const updatedProfile = await dispatch('updateProfileForProvider', profile)
        const user = Object.assign({}, updatedProfile, state.user, {userId: state.user._id})
        const subscription = await dispatch('createSubscription', user)
        commit('SET_SUBSCRIPTION', subscription)
        commit('NEXT_SIGNUP_STEP')
        return subscription.hostedPage
    },
    async createProfile({
        commit
    }, user) {
        const baseProfile = {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phone,
            user: user.userId
        }
        const mentorParent = getSelectedSubscription().mentor
        const mentor = {
            mentorToCreate: mentorParent ? {
                mentorParent
            } : {}
        }
        const profile = Object.assign(mentor, baseProfile)
        const profileResponse = await api.createEntry('profiles', profile)
        commit('SET_PROFILE', profileResponse)
    },
    async updateProfile(
        {
            commit,
            getters,
            dispatch
        },
        profile
    ) {
        const response = await api.updateEntry('profiles', getters.profile._id, Object.assign(profile))
        commit('SET_PROFILE', response)
        dispatch('getProfile')
        return response
    },
    async updateProfileForProvider(
        {
            commit,
            getters
        },
        profile
    ) {
        const mentorParent = getSelectedSubscription().mentor
        const mentor = {
            mentorToCreate: mentorParent ? {
                mentorParent
            } : {}
        }
        const response = await api.updateEntry('profiles', getters.profile._id, Object.assign(mentor, profile))
        commit('SET_PROFILE', response)
        return response
    },
    async finishSubscription(
        {commit, getters, state, dispatch}
    ) {
        const user = Object.assign({}, getters.profile, state.user, {userId: state.user._id})
        const subscription = await dispatch('createSubscription', user)
        commit('SET_SUBSCRIPTION', subscription)
        if (subscription.plan === 'shared') {
            dispatch('getAllLogin')
        }
    },
    async createSubscription({
        commit
    }, user) {
        const subscription = {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phone,
            user: user.userId,
            email: user.email,
            plan: getSelectedSubscription().plan
        }
        const response = await api.createEntry('subscriptions', subscription)
        commit('SET_SUBSCRIPTION', response)
        return response
    },
    async getSubscription({commit}) {
        const id = state.user.subscription._id
        const response = await api.getEntry('subscriptions', id)
        commit('SET_SUBSCRIPTION', response)
        return response.hostedPage
    },
    async completeSubscription(
        {
            commit,
            state
        },
        hostedPageId
    ) {
        const response = await api.updateEntry('subscriptions', state.subscription._id, {hostedPageId, isCompleted: true})
        commit('SET_SUBSCRIPTION', response)
    },
    async resetPassword({
        commit
    }, email) {
        const url = process.env.VUE_APP_URL + '/reset-password'
        try {
            await api.forgotPassword(email, url)
            commit('SET_MESSAGE', m.PASSWORD_RESET_SENT)
        } catch (error) {
            commit('SET_MESSAGE', m.PASSWORD_RESET_NOT_SENT)
        }
    },
    async confirmResetPassword({
        commit
    }, reset) {
        try {
            await api.resetPassword(reset.code, reset.password, reset.passwordConfirmation)
            commit('SET_MESSAGE', m.PASSWORD_UPDATED)
            router.push({name: 'Login'})
        } catch (error) {
            commit('SET_MESSAGE', m.PASSWORD_RESET_NOT_SENT)
        }
    }
}
const mutations = {
    SET_USER(s, user) {
        s.user = user
    },
    NEXT_SIGNUP_STEP(s) {
        s.signUp.step = s.signUp.step + 1
    },
    SET_MESSAGE_BOX(s, message) {
        MessageBox.alert(message.text, message.title, message.options)
    },
    SET_MESSAGE(s, message) {
        Message(Object.assign({}, message))
    },
    SET_PROFILE(s, profile) {
        s.profile = profile
    },
    SET_BUILDER(s, builder) {
        s.builder = builder
    },
    START_LOADING(s) {
        const options = {
            lock: true,
            // text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)'
        }
        s.loading = Loading.service(options)
    },
    STOP_LOADING(s) {
        if (s.loading) 
            s.loading.close()


        


    },
    LOGOUT() {
        api.clearToken()
        location.reload()
        localStorage.clear()
        router.push({name: 'Login'})
    },
    ERROR_LOGOUT(s) {
        router.push({name: 'Login'})
        if (s.loading) 
            s.loading.close()


        


        // MessageBox.alert('Please check your internet connection.', 'Server Error')
        // location.reload()
    },
    SET_SUBSCRIPTION(s, sub) {
        s.subscription = sub
    },
    SET_MENTOR(s, mentor) {
        s.userMentor = mentor
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
