<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col v-if="premiumprovider" :sm="{ span: 12 }">
        <span> Premium Content </span>
        <br />
        <img
          :src="premiumprovider.image.url"
          class="use-img"
          @click="handleSelectProvider"
        />
        <p class="small-text">{{ premiumprovider.name }}</p>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col v-if="usecategories" :sm="{ span: 12 }">
        <span> Uses Categories </span>
        <use-card
          v-if="usecategories"
          :uses="usecategories"
          @select="handleSelectUse"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UseCard from "@/components/uses/UseCards";
import { mapGetters } from "vuex";
import GetUseCategories from "@/graphql/queries/GetUseCategories.graphql";
import GetPremiumProvider from "@/graphql/queries/GetPremiumProvider.graphql";

export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: "recipes",
  apollo: {
    usecategories: {
      query: GetUseCategories,
    },
    premiumprovider: {
      query: GetPremiumProvider,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  components: {
    UseCard,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["useId"],
  data() {
    return {
      id: "5d94e0566a280a000e9b6b94",
    };
  },
  computed: {
    ...mapGetters({
      isLoaded: "content/isLoaded",
      purchases: "user/purchases",
      isOilMagic: "user/isOilMagic",
    }),
  },
  methods: {
    handleSelectUse(usecategories) {
      this.$router.push({ path: "/useCatagories/" + usecategories.id });
    },
    handleSelectProvider() {
      if (this.purchases.oilmagic || this.purchases.mentor || this.isOilMagic) {
        this.$router.push({ path: "/premiumUses/" + this.id });
      } else {
        this.$router.push({ path: "/premium" });
      }
    },
  },
};
</script>
<style lang="css" scoped>
.use-img {
  max-width: 100%;
  max-height: 150px;
  cursor: pointer;
}
.small-text {
  white-space: nowrap;
  font-size: 100%;
  margin-bottom: -2px;
}
</style>
