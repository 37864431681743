<template>
  <!-- <el-card shadow="never"> -->
  <el-table
    :data="listData"
    height="425"
    highlight-current-row
    :default-sort="{prop: 'name', order: 'ascending'}"
    style="width: 100%"
    class="list"
    @row-click="handleCurrentChange"
  >
    <el-table-column prop="name" label="Name"></el-table-column>
  </el-table>
  <!-- </el-card> -->
</template>

<script>
export default {
  props: {
    listData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('select', val)
    }
  }
}
</script>

<style scoped>
.list {
  cursor: pointer;
}
</style>
