<template>
  <div>
    <h1 class="head-m">Congratulations on registering with Droplii!</h1>
    <br />
    <el-col>
      <h1>Download Droplii!</h1>
      <a href="https://apps.apple.com/us/app/droplii-2-0/id1480595480">
        <img class="appStoreImg" src="@/assets/images/app-store.png" alt />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.droplii.app&hl=es"
      >
        <img class="playStoreImg" src="@/assets/images/google-play.png" alt />
      </a>
    </el-col>
    <el-col style="margin-top: -20px">
      <h1>Or</h1>
      <h1>Continue to Droplii web app!</h1>
      <el-button type="primary" @click="navHome">Let's Go!</el-button>
      <br />
      <br />
    </el-col>
  </div>
</template>
<script>
import { api } from '@/api/api';
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    navHome() {
      localStorage.clear()
      api.clearToken()
      this.$store.dispatch("user/login", this.user).then(() => {
        this.$router.push("/home");
      });
    },
  },
};
</script>

<style scoped>
.image {
  max-width: 40%;
}
.checkout {
  height: 600px;
  width: 100%;
}
.vl {
  width: 1px; /* Line width */
  background-color: black; /* Line color */
  height: 120px;
  display: inline-block;
}
.appStoreImg {
  width: 86.5%;
}
.playStoreImg {
  width: 100%;
}
.head-m {
  margin-top: 0px;
}
</style>
