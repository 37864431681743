<template>
  <div>
    <el-dialog title="Share" fullscreen :modal="false" width="100%" :visible.sync="dialogVisible">
      <h1>{{header}}</h1>
      <br>
      <a :href="`mailto:?subject=${title}&body=${text}`">
        <el-button type="text" plain icon="el-icon-edit-outline">Email</el-button>
      </a>
      <br>
      <a :href="`sms:?&body=${text}`">
        <el-button type="text" plain icon="el-icon-message">Text Message</el-button>
      </a>
      <br>
      <br>
      <el-button plain @click="$emit('closeDialog')">Done</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: 'How would you like to share?'
    },
    text: {
      type: String,
      default: 'share message'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
</style>
