<template>
  <div>
    <el-row v-if="type" :gutter="20">
      <el-col :sm="{ span: 6 }" :class="mobileSelected">
        <list
          v-loading="!products"
          :listData="products"
          @select="handleSelect"
        />
      </el-col>
      <el-col ref="col" :sm="{ span: 18 }">
        <router-view :itemId="selectedItemId" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import List from "@/components/List";
import GetProductCategories from "@/graphql/queries/GetProductCategories.graphql";

export default {
  apollo: {
    productcategories: {
      query: GetProductCategories,
    },
  },
  /*  watch: {
    id: {
      handler: 'selectId'
    }
  }, */
  components: {
    List,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["type", "id"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    selectedItemId() {
      return this.$route.params.itemId;
    },
    mobileSelected() {
      const isSelected = this.selectedItemId;
      return { "hidden-xs-only": isSelected };
    },
    products() {
      if (!this.productcategories) return [];
      return this.productcategories.find(
        (product) => product.slug === this.type
      ).products;
    },
  },
  methods: {
    handleSelect(item) {
      this.$router.push({ path: `/catalog/${this.type}/${item.id}` });
    },
  },
};
</script>
<style scoped>
