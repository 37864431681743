<template>
  <div v-loading="!recipes">
    <div v-if="recipes && recipes.length > 0">
      <div>
        <div v-for="recipes in recipesInPage" :key="recipes.id">
          <recipe-card
            shadow="never"
            :recipes="recipes"
            @selectRecipe="handleSelectRecipe"
          />
          <br />
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="recipes.length * 3"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <div v-else>
      <p>No Favorite Recipes yet...</p>
    </div>
  </div>
</template>

<script>
import RecipeCard from "@/components/recipes/RecipeCard";
import GetFavoriteRecipes from "@/graphql/queries/GetFavoriteRecipes.graphql";

export default {
  apollo: {
    recipes: {
      query: GetFavoriteRecipes,
      variables() {
        return {
          profileId: this.$store.state.user.user.profile.id,
        };
      },
      skip() {
        return !this.$store.state.user.user;
      },
      update(data) {
        return data.profile.favoriteRecipes;
      },
    },
  },
  components: {
    RecipeCard,
  },

  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    recipesInPage() {
      if (!this.recipes) return [];
      const recipesPerPage = 3;
      const startOfRecipes = recipesPerPage * (this.currentPage - 1);
      const endOfRecipes = recipesPerPage * this.currentPage;
      return this.recipes.slice(startOfRecipes, endOfRecipes);
    },
  },
  methods: {
    handleSelectRecipe() {},
    handleCurrentChange(selected) {
      this.currentPage = selected;
    },
  },
};
</script>

<style>
</style>
