<template>
  <div>
    <el-form ref="form" :rules="rules" :model="form" label-width="50px">
      <br />
      <el-form-item required>
        <span slot="label">
          <b-icon name="Name"></b-icon>
        </span>
        <el-col :span="12">
          <el-form-item prop="Name">
            <el-input v-model="form.name" placeholder="Name" />
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="CustomerPhoneNumber">
        <span slot="label">
          <b-icon name="Phone"></b-icon>
        </span>
        <el-input v-model="form.phone" type="tel" placeholder="Phone Number" />
      </el-form-item>
      <el-checkbox v-model="checked">
        <p>
          I agree to the
          <a
            class="termslink"
            href="https://droplii.com/terms-of-use/"
            target="_blank"
          >
            Privacy Policy
            & Terms of Use
          </a>
        </p>
      </el-checkbox>
    </el-form>
    <el-button type="primary" @click="onSignup('form')">Sign Up</el-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import router from '@/router'
export default {
  data() {
    const mentor = this.$route.query.m
    return {
      checked: false,
      form: {
        profileToCreate: {
          name: '',
          phone: '',
          mentor: mentor,
        },
      },
      rules: {
        Name: [
          {
            required: true,
            message: 'Please input first name',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please input phone number',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      profile: 'user/profile',
    }),
  },
  watch: {
    profile: {
      handler: 'handleProfile',
      immediate: true,
    },
  },
  methods: {
    onSignup(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && this.checked && this.form.profileToCreate.name != '') {
          const user = this.form
          this.$emit('signupProvider', user)
          router.push({ name: 'Successful Signup' })
        } else if (!valid) {
          this.$alert('Please finish adding signup details.', 'Oh no!', {
            confirmButtonText: 'OK',
          })
          return false
        } else if (this.form.profileToCreate.name == '') {
          this.$alert('Please finish adding signup details.', 'Oh no!', {
            confirmButtonText: 'OK',
          })
          return false
        } else if (!this.checked) {
          this.$alert(
            'Please check "I agree to the Privacy Policy & Terms of Use."',
            'Oh no!',
            {
              confirmButtonText: 'OK',
            }
          )
        }
      })
    },
    handleProfile(profile) {
      if (!profile) {
        this.$router.push({ name: 'Login' })
        return
      }
      this.form.firstName = profile.firstName
      this.form.lastName = profile.lastName
    },
  },
}
</script>

<style scoped>
.terms {
  font-size: 10px;
  color: grey;
}
</style>