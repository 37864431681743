<template>
  <div>
    <el-form :model="form" ref="form" :rules="rules" label-width="150px">
      <el-form-item label="Name" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Contacts">
        <el-col>
          <check-list
            ref="list"
            v-loading="!contacts"
            :listData="contactListWithName"
            @select="handleSelectionChange"
          />
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.push({ name: 'Contacts' })"
          >Cancel</el-button
        >
        <el-button
          v-loading="isSubmitting"
          type="primary"
          @click="onCreate('form')"
          >Create</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import CreateContactGroup from "@/graphql/mutations/CreateContactGroup.graphql";
import GetContacts from "@/graphql/queries/GetContacts.graphql";
import CheckList from "@/components/CheckList";

export default {
  components: {
    CheckList,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  apollo: {
    contacts: {
      query: GetContacts,
      variables() {
        return {
          id: this.$store.state.user.user.profile.id,
        };
      },
      skip() {
        return !this.$store.state.user.user;
      },
      update(data) {
        return data.profile.contacts;
      },
    },
  },
  data() {
    return {
      isSkipping: true,
      isSubmitting: false,
      contactsIds: [],
      form: {
        name: "",
        contacts: [
          {
            contact: "",
          },
        ],
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please input name",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    contactListWithName() {
      if (!this.contacts) return [];
      const names = this.contacts.map((c) =>
        Object.assign(c, { name: c.firstName + " " + c.lastName, id: c.id })
      );
      var sortedNames = names.sort((a, b) => {
        if (a.name < b.name) return -1;
        return a.name > b.name ? 1 : 0;
      });
      return sortedNames;
    },
  },
  watch: {
    id: {
      handler: "findGroup",
      immediate: true,
    },
  },
  methods: {
    notification() {
      this.$message({
        message: "Group succesfully created!",
        type: "success",
        showClose: true,
      });
    },
    async onCreate(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.contactsIds.length > 0) {
            //create Group
            await this.$apollo.mutate({
              mutation: CreateContactGroup,
              variables: {
                profile: this.$store.state.user.user.profile.id,
                name: this.form.name,
                contacts: this.contactsIds,
              },
              refetchQueries: [
                {
                  query: GetContacts,
                  variables: {
                    id: this.$store.state.user.user.profile.id,
                  },
                },
              ],
            });
          } else {
            this.$alert("please select at least one contact", "Oh no!", {
              confirmButtonText: "OK",
            });
            return false;
          }
          this.notification();
          await this.$router.push({ name: "Contacts" });
        } else {
          this.$alert("Please finish adding group details.", "Oh no!", {
            confirmButtonText: "OK",
          });
          return false;
        }
      });
    },
    handleSelectionChange(val, selected) {
      if (selected.select) {
        this.contactsIds.push(val.id);
      } else {
        const index = this.contactsIds.indexOf(val.id);
        if (index > -1) {
          this.contactsIds.splice(index, 1);
        }
      }
    },
    findGroup(id) {
      if (!id) return;
      const group = this.contactgroups.filter((c) => c.id === id);
      this.form = group;
    },
  },
};
</script>

<style>
</style>

