<template>
  <div>
    <div align="middle" type="flex" justify="center">
      <el-card class="el-card">
        <div class="star-left">
          <img
            class="left-star star-size"
            src="@/assets/images/starLeft.png"
            alt
          />
        </div>
        <br />
        <b slot="header">Best Day EVER!</b>
        <div>
          <b>{{ mentorName }}</b>
          <p>Has invited you to join! Please complete the Signup below</p>
        </div>
        <div class="card">
          <img class="star star-size" src="@/assets/images/starRight.png" alt />
        </div>
      </el-card>
    </div>
    <br />
    <div align="middle" type="flex" justify="center">
      <el-card class="el-card">
        <share-sign-up-form
          v-if="!signUpSuccess && !isProvider"
          @signup="onSignup"
        />
        <signup-provider-form
          v-else-if="!signUpSuccess && isProvider"
          @signupProvider="onSignupProvider"
        />
        <download-go v-else />
      </el-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoginForm from "@/components/auth/LoginForm";
import SignupProviderForm from "@/components/auth/SignupProviderForm";
import DownloadGo from "@/components/auth/DownloadGo";
import { mapGetters } from "vuex";
import ShareSignUpForm from "@/components/auth/ShareSignUpForm.vue";
export default {
  data() {
    return {
      mentor: null,
      signUpSuccess: false,
      mentorName: null,
    };
  },
  computed: {
    ...mapGetters(["signUpStep", "checkoutLink"]),
    isProvider() {
      return this.$route.query.isProvider;
    },
  },
  mounted: async function () {
    /* eslint-disable */
    const mentorId = this.$route.query.m;
    localStorage.setItem("mentor", mentorId);
    const profile = await axios.post(
      "https://api.pocketoils.com/mentor",
      { profile: mentorId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
      }
    );
    this.mentorName = profile.data;
  },
  methods: {
    onSignup: async function (user) {
      // add profileToCreate to object.
      // const profileId = this.$route.query.m
      // const newUser = Object.assign({profileId}, user)
      // this.setStorage()
      try {
        await this.$store.dispatch("user/register", user);
        this.signUpSuccess = true;
      } catch (error) {
        //
      }
    },
    onSignupProvider(user) {
      this.$store.dispatch("user/providerRegister", user).then(() => {
        this.signUpSuccess = true;
      });
    },
  },
  components: {
    LoginForm,
    DownloadGo,
    SignupProviderForm,
    ShareSignUpForm,
  },
};
</script>

<style scoped>
.image {
  max-width: 30%;
}
.star-left {
  position: relative;
  width: 100px;
  height: 100px;
  margin: -50px;
  z-index: 1;
  left: -200px;
  top: -10px;
}
.el-card {
  max-width: 500px;
}
.star-right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 100px;
}
.star {
  position: absolute;
  right: -20px;
  top: -50px;
}
.card {
  position: relative;
  z-index: 1;
}
.star-size {
  max-width: 50px;
}
</style>

