<template>
  <div v-loading="!user">
    <div>
      <img class="image" :src="PurchaseImage" />
    </div>
    <div>
      <p style="color: gray">
        {{ purchaseString }}
      </p>
    </div>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="subscription"
        :clientReferenceId="userId"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (loading = v)"
      />
      <el-button class="button" plain @click="submit">
        {{ purchasePrice }}
      </el-button>
    </div>
  </div>
</template>
<script>
import GetPremiumProvider from "@/graphql/queries/GetPremiumProvider.graphql";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { mapGetters } from "vuex";

export default {
  components: {
    StripeCheckout,
  },
  apollo: {
    premiumprovider: {
      query: GetPremiumProvider,
      variables() {
        return {
          id: "5d94e0566a280a000e9b6b94",
        };
      },
    },
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_TOKEN;
    return {
      isPremium: false,
      loading: false,
      lineItems: [
        {
          price: "price_1J8CuUAPwQt4sTQ5sPPI1Mc3", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: process.env.VUE_APP_URL + "/sucess",
      cancelURL: process.env.VUE_APP_URL + "/cancel",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    userId() {
      return this.user ? this.user.id : "";
    },
    PurchaseImage() {
      if (!this.premiumprovider) return [];
      return this.premiumprovider.image.url;
    },
    purchaseString() {
      if (!this.premiumprovider) return [];
      return `Subscribe to ${this.premiumprovider.name} to view their premium content!`;
    },
    purchasePrice() {
      if (!this.premiumprovider) return [];
      return "19.99/year";
    },
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<style>
.button-m {
  margin: 10px;
}
.icon {
  margin-right: 10px;
}
.image {
  height: 200px;
  width: 200px;
}
.button {
  background-color: white;
  color: #9dd49f;
  border: 2px solid #9dd49f;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
}
</style>