<template>
  <div v-loading="!user">
    <div class="div">
      <img class="image" src="@/assets/images/group.png" />
    </div>
    <div>
      <p style="color: gray">
        Builder Subscription: Allows you to add members to your team
      </p>
    </div>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="subscription"
        :clientReferenceId="userId"
        :pk="publishableKey"
        :line-items="monthLineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (monthLoading = v)"
      />
      <el-button class="montly" plain @click="montly"> $13.99/month </el-button>
    </div>
    <div>
      <stripe-checkout
        ref="checkoutRefYearly"
        mode="subscription"
        :clientReferenceId="userId"
        :pk="publishableKey"
        :line-items="yearLineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (yearLoading = v)"
      />
      <el-button class="yearly" plain @click="yearly"> $139.99/year </el-button>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { mapGetters } from "vuex";
export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_TOKEN;
    return {
      monthLoading: false,
      yearLoading: false,
      monthLineItems: [
        {
          price: "price_1J8CxSAPwQt4sTQ5ZgfypLwa",
          quantity: 1,
        },
      ],
      yearLineItems: [
        {
          price: "price_1JCngNAPwQt4sTQ5qJSI7QKf",
          quantity: 1,
        },
      ],
      successURL: process.env.VUE_APP_URL + "/sucess",
      cancelURL: process.env.VUE_APP_URL + "/cancel",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    userId() {
      return this.user ? this.user.id : "";
    },
  },
  methods: {
    montly() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    yearly() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRefYearly.redirectToCheckout();
    },
  },
};
</script>

<style>
.div {
  margin-bottom: 20px;
}
.image {
  width: 300px;
  height: 250px;
}
.text {
  white-space: pre;
  font-size: 17px;
  color: darkgray;
}
.montly {
  background-color: white;
  color: #9dd49f;
  border: 2px solid #9dd49f;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.yearly {
  background-color: white;
  color: #e6c68b;
  border: 2px solid #e6c68b;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
}
</style>
