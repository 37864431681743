<template>
  <div>
    <el-row :gutter="15" type="flex" justify="center">
      <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
        <el-card>
          <div slot="header" class="clearfix">
            <b>Upgrade to add business contacts</b>
          </div>
          <p>
            Upgrade to a Droplii builder subscription to manage your contacts.
          </p>
          <el-button type="info" icon="el-icon-info" @click="navToUpgrade"
            >Upgrade now!</el-button
          >
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  methods: {
    navToUpgrade() {
      this.$router.push({
        name: "BuilderUpgrade",
      });
    },
  },
};
</script>

<style>
.button-m {
  margin: 10px;
}
.icon {
  margin-right: 10px;
}
</style>
