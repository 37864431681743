<template>
  <div>
    <br />
    <el-form ref="form" :rules="rules" :model="form" label-width="50px">
      <br />
      <el-form-item prop="email">
        <span slot="label">
          <b-icon name="Username"></b-icon>
        </span>
        <el-input
          v-model="form.email"
          type="email"
          autocapitalize="off"
          autocorrect="off"
          placeholder="Email"
        />
      </el-form-item>
      <el-form-item prop="confirmEmail">
        <span slot="label">
          <b-icon name="Username"></b-icon>
        </span>
        <el-input
          v-model="form.confirmEmail"
          type="email"
          autocapitalize="off"
          autocorrect="off"
          placeholder="Confirm Email"
        />
      </el-form-item>
      <el-form-item prop="password">
        <span slot="label">
          <b-icon name="Password"></b-icon>
        </span>
        <el-input
          v-model="form.password"
          placeholder="Password"
          type="password"
          :auto-complete="'off'"
        />
      </el-form-item>
      <el-form-item prop="profileToCreate.name">
        <span slot="label">
          <b-icon name="Name"></b-icon>
        </span>
        <el-input v-model="form.profileToCreate.name" placeholder="Name" />
      </el-form-item>
      <el-form-item prop="profileToCreate.phoneNumber">
        <span slot="label">
          <b-icon name="Phone"></b-icon>
        </span>
        <el-input
          v-model="form.profileToCreate.phoneNumber"
          type="tel"
          placeholder="Phone Number"
        />
      </el-form-item>
      <el-checkbox v-model="checked">
        <p>
          I agree to the
          <a
            class="termslink"
            href="https://droplii.com/terms-of-use/"
            target="_blank"
            >Privacy Policy & Terms of Use</a
          >
        </p>
      </el-checkbox>
    </el-form>
    <el-button type="primary" @click="onSignup('form')">Sign Up</el-button>
    <vue-apple-signin class="appleButton"></vue-apple-signin>
  </div>
</template>

<script>
import VueAppleSignin from "vue-apple-signin";
import Vue from "vue";

export default {
  data() {
    const mentor = this.$route.query.m;
    return {
      checked: false,
      form: {
        email: "",
        confirmEmail: "",
        password: "",
        profileToCreate: {
          name: "",
          phoneNumber: "",
          mentor: mentor,
        },
      },
      rules: {
        email: [
          { required: true, message: "Please input email", trigger: "blur" },
        ],
        confirmEmail: [
          {
            required: true,
            message: "Please input confirm email",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "Please input password", trigger: "blur" },
        ],
        profileToCreate: {
          name: [
            {
              required: true,
              message: "Please input name",
              trigger: "blur",
            },
          ],
          phoneNumber: [
            {
              required: true,
              message: "Please input phone number",
              trigger: "blur",
            },
          ],
        },
      },
    };
  },
  created() {
    Vue.use(VueAppleSignin, {
      clientId: "com.droplii.droplii",
      scope: "name email",
      redirectURI: "https://api.pocketoils.com/auth/apple/redirect",
      state: "STATE",
    });
  },
  methods: {
    onSignup(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && this.checked && this.form.profileToCreate.name != "") {
          const user = this.form;
          user.username = user.email;
          this.$emit("signup", user);
        } else if (!valid) {
          this.$alert("Please finish adding signup details.", "Oh no!", {
            confirmButtonText: "OK",
          });
          return false;
        } else if (this.form.profileToCreate.name == "") {
          this.$alert("Please finish adding signup details.", "Oh no!", {
            confirmButtonText: "OK",
          });
          return false;
        } else if (!this.checked) {
          this.$alert(
            'Please check "I agree to the Privacy Policy & Terms of Use."',
            "Oh no!",
            {
              confirmButtonText: "OK",
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.terms {
  font-size: 10px;
  color: grey;
}
.appleButton {
  margin-top: 20px;
  height: 60px;
  width: 200px;
}
</style>
