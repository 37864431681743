<template >
  <div v-loading="!recipe">
    <div>
      <el-button
        v-if="isMobile()"
        class="backButton"
        round
        plain
        type="info"
        icon="el-icon-back"
        @click="$router.go(-1)"
        >Back</el-button
      >
    </div>
    <el-row align="middle" type="flex" justify="center" :gutter="10">
      <el-col :xs="{ span: 22 }" :sm="{ span: 12 }" :lg="{ span: 10 }">
        <div v-if="recipe && recipe.name">
          <h1>{{ recipe.name }}</h1>
          <p v-if="recipe.author">Souce: {{ recipe.author.firstName }}</p>
          <a>Up Votes: {{ recipe.upvotes ? recipe.upvotes.length : 0 }}</a>
          <br />
          <br />
          <el-button
            class="button-m"
            type="info"
            plain
            icon="el-icon-share"
            @click="onShare"
            >Share</el-button
          >
          <el-button
            class="button-m"
            :plain="!userHasUpVoted"
            :type="userHasUpVoted ? 'primary' : ''"
            icon="el-icon-arrow-up"
            @click="onVoteOrRemove"
            >Up Vote</el-button
          >
          <el-button
            class="button-m"
            :plain="!userHasFavorited"
            :type="userHasFavorited ? 'primary' : ''"
            @click="onFavoriteOrRemove"
          >
            <font-awesome-icon icon="heart" class="icon" />Favorite
          </el-button>
          <br />
          <br />
          <el-collapse v-model="activeCollapse">
            <el-collapse-item title="Ingredients" name="1">
              <p v-for="i in recipe.ingredients" :key="i.id">
                {{ i.quantity }} {{ i.unit }} {{ i.name }}
              </p>
            </el-collapse-item>
            <el-collapse-item title="Instructions" name="2">
              <div class="justify-text">
                <p>{{ recipe.instructions }}</p>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Uses" name="3">
              <span v-for="use in recipe.uses" :key="use.id" class="pad-button">
                <use-button
                  v-if="use"
                  :useApplication="use"
                  @select="handleSelectUse"
                />
              </span>
            </el-collapse-item>
            <el-collapse-item title="Testimonials" name="4">
              <div
                v-for="testimonial in recipeTestimonials"
                :key="testimonial.id"
              >
                <testimonial
                  :testimonial="testimonial"
                  @likeTestimonial="onLikeTestimonial"
                />
              </div>
              <el-button
                v-if="!isAddTestimonial"
                @click="isAddTestimonial = true"
                >Add Testimonials</el-button
              >
              <create-testimonial
                v-else
                :profile="profile"
                :recipe="recipe"
                @cancel="isAddTestimonial = false"
              />
            </el-collapse-item>
          </el-collapse>
          <b-share
            header="How would you like to share?"
            :title="recipe.Title"
            :text="createTextForRecipe()"
            :dialogVisible="shareDialog"
            @closeDialog="shareDialog = false"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UseButton from "@/components/uses/UseButton";
import Testimonial from "@/components/recipes/Testimonial";
import CreateTestimonial from "@/components/recipes/CreateTestimonial";
import { mapGetters } from "vuex";
import GetRecipeQuery from "@/graphql/queries/GetRecipe.graphql";
import AddUpvoteMutation from "@/graphql/mutations/AddUpvoteMutation.graphql";
import RemoveUpvoteMutation from "@/graphql/mutations/RemoveUpvoteMutation.graphql";
import AddFavoriteMutation from "@/graphql/mutations/AddFavoriteMutation.graphql";
import RemoveFavoriteMutation from "@/graphql/mutations/RemoveFavoriteMutation.graphql";
import GetFavoriteRecipesQuery from "@/graphql/queries/GetFavoriteRecipes.graphql";
import AddLikeMutation from "@/graphql/mutations/AddLikeMutation.graphql";
import RemoveLikeMutation from "@/graphql/mutations/RemoveLikeMutation.graphql";

export default {
  components: {
    UseButton,
    Testimonial,
    CreateTestimonial,
  },
  props: {
    recipeId: {
      type: String,
      default: null,
    },
    length: {
      type: String,
      default: null,
    },
  },

  apollo: {
    recipe: {
      query: GetRecipeQuery,
      variables() {
        return {
          id: this.$route.params.recipeId,
          profile: this.$store.state.user.profile.id,
        };
      },
      skip() {
        return !this.$store.state.user.profile;
      },
    },
  },
  data() {
    return {
      activeCollapse: ["1", "2", "3", "4"],
      shareDialog: false,
      isAddTestimonial: false,
      recipe: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: "user/profile",
      favoriteRecipes: "content/favoriteRecipes",
      upvoteRecipes: "content/upvoteRecipes",
      purchases: "user/purchases",
    }),
    userHasUpVoted() {
      return this.recipe.upvotes.some(
        (upvotes) => upvotes.id === this.profile.id
      );
    },
    userHasFavorited() {
      return this.recipe.favorites.some(
        (favorites) => favorites.id == this.profile.id
      );
    },
    recipeTestimonials() {
      return Object.assign(
        this.recipe.testimonials,
        this.recipe.myTestimonials
      );
    },
  },
  methods: {
    isMobile() {
      if (screen.width <= 769) {
        return true;
      } else {
        return false;
      }
    },
    getCategory(UseCategoryId) {
      return this.categories.find((c) => c.Id === UseCategoryId);
    },
    handleSelectUse(recipe) {
      this.$router.push({ path: "/uses/" + recipe.id });
      this.$emit("closeDialog");
    },
    createTextForRecipe() {
      const title = this.recipe.Title + " %0D%0A %0D%0A";
      // const ingredients =
      //   this.recipe.ingredients.map(
      //     i => `${i.Quantity || ''} ${i.Unit || ''} ${i.Name || ''}`
      //   ).join('%0D%0A') + '%0D%0A %0D%0A'
      const instructions = this.recipe.Instructions + "%0D%0A %0D%0A";
      const droplii = "Shared with Droplii.com";
      return title + instructions + droplii;
    },
    onShare() {
      if (this.purchases.mentor || this.purchases.share) {
        if (navigator.share) {
          const shareText =
            `Ingredients:\n${this.recipe.ingredients.map(
              (ingredient) =>
                ingredient.quantity +
                " " +
                ingredient.unit +
                " " +
                ingredient.name +
                " "
            )}` + `\n\nInstructions:\n${this.recipe.instructions}`;
          navigator.share({
            title: this.recipe.name,
            text: shareText,
            url: "https://developer.mozilla.org",
          });
        } else {
          this.shareDialog = true;
        }
      } else {
        this.$router.push({ path: "/share" });
      }
    },
    onVoteOrRemove: function () {
      if (this.userHasUpVoted) {
        this.$apollo.mutate({
          mutation: RemoveUpvoteMutation,
          variables: {
            recipe: this.$route.params.recipeId,
            profile: this.$store.state.user.user.profile.id,
          },
        });
      } else {
        this.$apollo.mutate({
          mutation: AddUpvoteMutation,
          variables: {
            recipe: this.$route.params.recipeId,
            profile: this.$store.state.user.user.profile.id,
          },
        });
      }
    },
    onFavoriteOrRemove() {
      if (this.userHasFavorited) {
        this.$apollo.mutate({
          mutation: RemoveFavoriteMutation,
          variables: {
            recipe: this.$route.params.recipeId,
            profile: this.$store.state.user.user.profile.id,
          },
          refetchQueries: [
            {
              query: GetFavoriteRecipesQuery,
              variables: {
                profileId: this.$store.state.user.user.profile.id,
              },
            },
          ],
        });
      } else {
        this.$apollo.mutate({
          mutation: AddFavoriteMutation,
          variables: {
            recipe: this.$route.params.recipeId,
            profile: this.$store.state.user.user.profile.id,
          },
          refetchQueries: [
            {
              query: GetFavoriteRecipesQuery,
              variables: {
                profileId: this.$store.state.user.user.profile.id,
              },
            },
          ],
        });
      }
    },
    onLikeTestimonial(testimonial) {
      var liked = testimonial.likes.some(
        (likes) => likes.id === this.$store.state.user.user.profile.id
      );
      if (liked) {
        this.$apollo.mutate({
          mutation: RemoveLikeMutation,
          variables: {
            testimonial: testimonial.id,
            profile: this.$store.state.user.user.profile.id,
          },
          refetchQueries: [
            {
              query: GetRecipeQuery,
              variables: {
                id: this.$route.params.recipeId,
                profile: this.$store.state.user.user.profile.id,
              },
            },
          ],
        });
      } else {
        this.$apollo.mutate({
          mutation: AddLikeMutation,
          variables: {
            testimonial: testimonial.id,
            profile: this.$store.state.user.user.profile.id,
          },
          refetchQueries: [
            {
              query: GetRecipeQuery,
              variables: {
                id: this.$route.params.recipeId,
                profile: this.$store.state.user.user.profile.id,
              },
            },
          ],
        });
      }
    },
  },
};
</script>

<style scoped>
.aa-button {
  padding: 20px;
  margin: 20px;
}
.backButton {
  float: left;
  margin-top: -10px;
}
.icon {
  margin-right: 5px;
}
.justify-text {
  text-align: justify;
}
.button-m {
  margin-top: 10px;
}
</style>
