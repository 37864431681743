<template>
  <div v-loading="!builder">
    <el-row align="left" type="flex" justify="left" :gutter="20">
      <el-button
        v-if="isMobile()"
        class="backButton"
        round
        plain
        type="info"
        icon="el-icon-back"
        @click="$router.go(-1)"
        >Back</el-button
      >
    </el-row>
    <el-row align="middle" type="flex" justify="center" :gutter="10">
      <h1>Builder Profile</h1>
    </el-row>
    <el-row align="middle" type="flex" justify="center" :gutter="20">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span v-if="mentor.name" class="span">{{ mentor.name }}</span>
        </div>
        <div class="div">
          <el-row>
            <span> Phone: </span>
            <a
              v-if="mentor.phoneNumber"
              class="a-pad"
              :href="'tel:' + mentor.phoneNumber"
              >call</a
            >
            <a
              v-if="mentor.phoneNumber"
              class="a-pad"
              :href="'sms:' + mentor.phoneNumber"
              >text</a
            >
            <span v-if="mentor.phoneNumber">{{ mentor.phoneNumber }}</span>
            <span v-else> Builder doesn't have a phone yet </span>
          </el-row>
        </div>
        <div class="div">
          <el-row v-if="mentor.user.email">
            <span> Email: </span>
            <a class="a-pad" :href="'mailto:' + mentor.user.email"
              ><span>{{ mentor.user.email }}</span></a
            >
          </el-row>
          <el-row v-else>
            <span> Email: </span>
            <span>Builder doesn't have an email yet </span>
          </el-row>
        </div>
        <div>
          <el-row v-if="mentor.bio">
            <span>Bio: </span>
            <span>{{ mentor.bio }}</span>
          </el-row>
          <el-row v-else>
            <span>Bio: </span>
            <span>Builder doesn't have a Bio yet</span>
          </el-row>
        </div>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      mentor: {
        name: "",
        phoneNumber: "",
        bio: "",
        user: {
          email: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      builder: "user/builder",
    }),
  },
  watch: {
    builder: {
      handler: "setBuilder",
      immediate: true,
    },
  },
  methods: {
    setBuilder() {
      if (!this.builder) return;
      this.mentor = this.builder;
    },
    isMobile() {
      if (screen.width <= 769) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.span {
  font-weight: bold;
}
.backButton {
  float: left;
  margin-top: -10px;
}
.div {
  margin-bottom: 20px;
}
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.box-card {
  width: 480px;
}
.a-pad {
  padding-right: 10px;
}
</style>