<template>
  <div>
    <el-row>
      <el-col :sm="{ span: 12, offset: 6 }">
        <img class="image" src="@/assets/images/Droplii_logo-green.png" />
        <br />
        <br />
        <br />
      </el-col>
    </el-row>
    <el-row align="middle" type="flex" justify="center" :gutter="20">
      <el-col :sm="{ span: 12 }" :lg="{ span: 8 }">
        <el-steps :space="200" :active="signUpStep" simple>
          <el-step title="Login" icon="el-icon-edit"></el-step>
          <el-step title="Checkout Upgrade" icon="el-icon-goods"></el-step>
          <el-step title="Droplii" icon="el-icon-check"></el-step>
        </el-steps>
      </el-col>
    </el-row>
    <br />
    <el-row align="middle" type="flex" justify="center" :gutter="20">
      <el-col :sm="{ span: 12 }" :lg="{ span: 8 }">
        <el-card v-if="signUpStep === 0" class="box-card">
          <div slot="header">
            <span>
              <strong>Login</strong>
            </span>
          </div>
          <login-form isUpgrade />
          <p>
            <router-link :to="{ path: 'signup' }"
              >don't have an account?</router-link
            >
          </p>
        </el-card>
        <el-card v-if="signUpStep === 1">
          <iframe class="checkout" :src="checkoutLink" frameborder="0"></iframe>
        </el-card>
        <el-card v-if="signUpStep == 2">
          <download-go />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm";
import DownloadGo from "@/components/auth/DownloadGo";

import { mapGetters } from "vuex";

export default {
  components: {
    LoginForm,
    DownloadGo,
  },
  computed: {
    ...mapGetters(["signUpStep", "checkoutLink"]),
  },
};
</script>

<style scoped>
.image {
  max-width: 40%;
}
.checkout {
  height: 600px;
  width: 100%;
}
</style>
