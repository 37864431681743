<template>
  <div>
    <el-row :gutter="20">
      <el-col :class="mobileSelected" :sm="{ span: 6 }">
        <list :list-data="uses" @select="handleSelectRecipeCategory" />
      </el-col>
      <el-col :sm="{ span: 18 }">
        <router-view :category-id="selectedUseId" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import List from "@/components/List";
import GetPremiumProvider from "@/graphql/queries/GetPremiumProvider.graphql";

export default {
  name: "Uses",
  components: {
    List,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["providerId"],
  apollo: {
    premiumprovider: {
      query: GetPremiumProvider,
      variables() {
        return {
          id: "5d94e0566a280a000e9b6b94",
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    mobileSelected() {
      const isSelected = !!this.selectedUseId;
      return { "hidden-xs-only": isSelected };
    },
    selectedUseId() {
      return this.$route.params.premiumId;
    },
    uses() {
      if (!this.premiumprovider) return [];
      return this.premiumprovider.premiumuses;
    },
  },
  methods: {
    handleSelectRecipeCategory(item) {
      this.$router.push({ path: `/premiumUses/${this.providerId}/${item.id}` });
    },
  },
};
</script>
<style scoped>
