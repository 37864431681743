<template>
  <div>
    <br />
    <el-form ref="form" :model="viewForm" label-width="150px">
      <el-form-item label="Group Name:">
        <b>{{ viewForm.name }}</b>
      </el-form-item>
      <el-form-item label="Contacts:">
        <el-col>
          <list :listData="contactListWithName" />
        </el-col>
      </el-form-item>
      <el-button
        v-if="!viewForm.MentorId"
        round
        size="small"
        icon="el-icon-edit-outline"
        @click="$emit('edit')"
        >Edit Group</el-button
      >
      <el-button
        v-if="!viewForm.MentorId"
        round
        size="small"
        type="danger"
        icon="el-icon-delete"
        @click="onDeleteGroup"
        >Delete Group</el-button
      >
    </el-form>
  </div>
</template>
<script>
import { DeleteContactGroup } from "@/graphql/mutations/EditContactGroup.graphql";
import GetContacts from "@/graphql/queries/GetContacts.graphql";
import List from "@/components/List";

export default {
  components: {
    List,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["viewForm"],
  computed: {
    contactListWithName() {
      if (!this.viewForm.contacts) return [];
      return this.viewForm.contacts.map((c) =>
        Object.assign(c, { name: c.firstName + " " + c.lastName })
      );
    },
  },
  methods: {
    notification() {
      this.$message({
        message: "Group succesfully deleted!",
        type: "success",
        showClose: true,
      });
    },
    onDeleteGroup() {
      this.$confirm(
        "This will permanently delete this group. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          //delete contact mutation, and update contacts list
          await this.$apollo.mutate({
            mutation: DeleteContactGroup,
            variables: {
              id: this.viewForm.id,
            },
            refetchQueries: [
              {
                query: GetContacts,
                variables: {
                  id: this.$store.state.user.user.profile.id,
                },
              },
            ],
          });
          this.notification();
          await this.$router.push({ name: "Contacts" });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="css" scoped>
.a-pad {
  padding-right: 10px;
}
</style>
