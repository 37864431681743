import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store.js'
import Login from '@/views/auth/Login'
import Signup from '@/views/auth/Signup'
import Upgrade from '@/views/auth/Upgrade'
import SignupShared from '@/views/auth/SignupShared'
import SuccessfulSignupShared from '@/views/auth/SuccessfulSignupShared'
import OilMagic from '@/views/auth/OilMagic'
import ConfirmOilMagic from '@/views/auth/ConfirmOilMagic'
import ResetPassword from '@/views/auth/ResetPassword'
import ConfirmResetPassword from '@/views/auth/ConfirmResetPassword'
import Home from '@/views/Home'
import Catalog from '@/views/catalog/Catalog'
import CatalogCategories from '@/views/catalog/CatalogCategories'
import SelectedCatalogItem from '@/views/catalog/SelectedCatalogItem'
import Profile from '@/views/settings/Profile'
import Feedback from '@/views/settings/Feedback'
import Builder from '@/views/settings/Builder'
import Account from '@/views/settings/Account'
import Settings from '@/views/settings/Settings'
import UseCatagory from '@/views/uses/UseCatagory'
import Uses from '@/views/uses/Uses'
import PremiumUses from '@/views/uses/PremiumUses'
import Checkout from '@/views/Checkout'
import ContactsTab from '@/views/contacts/ContactsTab'
import SelectedContact from '@/views/contacts/SelectedContact'
import SelectedGroup from '@/views/contacts/SelectedGroup'
import CreateContact from '@/views/contacts/CreateContact'
import CreateGroup from '@/views/contacts/CreateGroup'
import RecipeCategory from '@/views/recipes/RecipeCategory'
import PremiumCategory from '@/views/recipes/PremiumCategory'
import Recipe from '@/views/recipes/Recipe'
import PremiumRecipe from '@/views/recipes/PremiumRecipe'
import BuilderUpgrade from '@/views/upgrade/BuilderUpgrade'
import Sucess from '@/views/upgrade/Sucess'
import Cancel from '@/views/upgrade/Cancel'
import Basic from "@/views/upgrade/Basic";
import PremiumProvider from "@/views/upgrade/PremiumProvider";
import Share from "@/views/upgrade/Share";
import AppleError from "@/views/auth/AppleError"
import {api} from '@/api/api'
Vue.use(Router)

const signupParamsCheck = (to, from, next) => {
    if (to.query.subscription) {
        store.commit('SET_SIGNUP_PARAMS', to.query)
    } else if (to.query.success) {
        store.commit('SET_ACCESS_REFRESH')
        store.dispatch('signUpSucess')
    }
    next()
}
const providerAuthCheck = (to, from, next) => {
    if (to.query.access_token) {
        next()
        // if they have a provider call back in the url
        store.dispatch('user/authenticateProvider')
    } else {
        next()
    }
}

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
            beforeEnter: providerAuthCheck
        },
        {
            path: '/signupshare',
            name: 'Signup Share',
            component: SignupShared
        },
        {
            path: '/successfulsignupshared',
            name: 'Successful Signup',
            component: SuccessfulSignupShared
        },
        {
            path: '/oilmagic',
            name: 'OilMagic',
            component: OilMagic
        }, {
            path: '/confirmoilmagic',
            name: 'ConfirmOilMagic',
            component: ConfirmOilMagic
        }, {
            path: '/signup',
            name: 'Signup',
            component: Signup,
            beforeEnter: signupParamsCheck
        }, {
            path: '/reset',
            name: 'Reset Password',
            component: ResetPassword
        }, {
            path: '/reset-password',
            name: 'Confirm Reset',
            component: ConfirmResetPassword
        }, {
            path: '/upgrade',
            name: 'Upgrade',
            component: Upgrade,
            beforeEnter: signupParamsCheck
        }, {
            path: '/home',
            name: 'Home',
            component: Home
        }, {
            path: '/appleissue',
            name: 'AppleIssue',
            component: AppleError
        }, {
            path: '/',
            redirect: '/home'
        }, {
            path: '/catalog/',
            name: 'Catalog',
            component: CatalogCategories,
            props: true
        }, {
            path: '/catalog/:type',
            name: 'Catalog Items',
            component: Catalog,
            props: true,
            children: [
                {
                    path: ':itemId',
                    component: SelectedCatalogItem,
                    props: true
                },
            ]
        }, {
            path: '/profile',
            name: 'Profile',
            component: Profile
        }, {
            path: '/feedback',
            name: 'feedback',
            component: Feedback
        }, {
            path: '/builder',
            name: 'builder',
            component: Builder
        }, {
            path: '/account',
            name: 'Account',
            component: Account
        }, {
            path: '/settings',
            name: 'Settings',
            component: Settings
        }, {
            path: '/useCatagories',
            name: 'Uses and Recipes',
            component: UseCatagory
        }, {
            path: '/builderUpgrade',
            name: 'BuilderUpgrade',
            component: BuilderUpgrade
        }, {
            path: '/basic',
            name: 'BasicUpgrade',
            component: Basic
        }, {
            path: '/premium',
            name: 'PremiumUpgrade',
            component: PremiumProvider
        }, {
            path: '/share',
            name: 'ShareUpgrade',
            component: Share
        }, {
            path: '/sucess',
            name: 'Sucess',
            component: Sucess
        }, {
            path: '/cancel',
            name: 'Cancel',
            component: Cancel
        }, {
            path: '/useCatagories/:useId',
            name: 'Uses',
            component: Uses,
            props: true,
            children: [
                {
                    path: ':categoryId',
                    component: RecipeCategory,
                    props: true
                },
            ]
        }, {
            path: '/premiumUses/:providerId',
            name: 'PremiumUses',
            component: PremiumUses,
            props: true,
            children: [
                {
                    path: ':premiumId',
                    component: PremiumCategory,
                    props: true
                },
            ]
        }, {
            path: '/checkout',
            name: 'Checkout',
            component: Checkout
        }, {
            path: '/contacts',
            name: 'Contacts',
            component: ContactsTab,
            children: [
                {
                    path: '/contact/:contactId',
                    name: 'Contact',
                    component: SelectedContact,
                    props: true
                }, {
                    path: '/group/:groupId',
                    name: 'Group',
                    component: SelectedGroup,
                    props: true
                },
            ]
        }, {
            path: '/createContact/:type?',
            name: 'Create Contact',
            component: CreateContact,
            props: true
        }, {
            path: '/createGroup/:type?',
            name: 'Create Group',
            component: CreateGroup,
            props: true
        }, {
            path: '/uses/:categoryId?',
            name: 'Recipe Category',
            component: RecipeCategory,
            props: true
        }, {
            path: '/recipe/:recipeId?',
            name: 'Recipe',
            component: Recipe,
            props: true
        }, {
            path: '/premiumRecipe/:premiumId?',
            name: 'PremiumRecipe',
            component: PremiumRecipe,
            props: true
        },
    ]
})

router.beforeEach(async (to, from, next) => {
    // will have to check user.MentorName to know if they have payed the first time. Might have to check something else later.
    // signupshare?m=mentorcode4cf4f
    if (to.name == "Home") {
        if (to.query.token) {
            try {
                localStorage.setItem('token', to.query.token)
                // set token to use api request
                api.setToken(to.query.token)
                // update profile with mentor Id from apple Sign in, update user with basic purchase
                if (to.query.shared && to.query.user) { // get the mentor id from localStorage
                    const user = JSON.parse(to.query.user)
                    const mentorId = localStorage.getItem('mentor')
                    await api.axios.post('/update/shared/user', {
                        mentor: mentorId,
                        user: user
                    })
                    // reload user data and content data after add mentor and basic purchase
                    await store.dispatch('user/getAllLogin')
                } else { // load user and content data
                    await store.dispatch('user/getAllLogin')
                }
                next()
            } catch (error) {
                next('/appleissue')
            }
        }
    }

    const token = localStorage.getItem('token')
    let isAuthenticated = false
    if (token) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + (
                '00' + c.charCodeAt(0).toString(16)
            ).slice(-2)
        }).join(''))
        const tokenDecode = JSON.parse(jsonPayload)
        const expirationToken = tokenDecode.exp * 1000
        const date = new Date()
        const currentDate = Date.parse(date)
        if (currentDate >= expirationToken) {
            isAuthenticated = false
        } else {
            isAuthenticated = true
        }
    }

    const isLoggedIn = store.getters['user/isAuth']
    const isNotToAuthPage = to.name !== 'Login' && to.name !== 'Signup' && to.name !== 'Signup Share' && to.name !== 'Successful Signup' && to.name !== 'Upgrade' && to.name !== 'Reset Password' && to.name !== 'OilMagic' && to.name !== 'ConfirmOilMagic' && to.name !== 'Confirm Reset' && to.name !== 'AppleIssue'

    // Logout if token expire
    if (isNotToAuthPage) {
        if (! isAuthenticated) {
            next('/login')
        }
    }

    // validate navigation
    if (isNotToAuthPage && ! isLoggedIn) { // reload data hen user reload page
        if (isAuthenticated) {
            store.dispatch('user/getAllLogin').catch(() => store.commit('user/ERROR_LOGOUT'))
            next()
            return
        }
        next('/login')
    } else {
        next()
        return
    }
})

export default router
