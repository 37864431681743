<template>
  <div>
    <span> Successful!</span>
  </div>
</template>

<script>
export default {
  created() {
    this.open();
  },
  methods: {
    open() {
      this.$alert("Purchase Successful!", "Successful", {
        type: "success",
        confirmButtonText: "OK",
        center: true,
        callback: async () => {
          await this.$store.dispatch("user/getPurchases");
          this.$router.push({ name: "Home" });
        },
      });
    },
  },
};
</script>
