<template>
  <div>
    <el-button
      v-if="useApplication"
      class="button-m"
      round
      @click="$emit('select', useApplication)"
    >
      <b>{{ useApplication.name }}</b>

      <!-- 
         strapi dont have useAplication for uses
         <b-icon name="aromatic" v-if="useApplication.isAromatic" class="el-icon-right" height="30px"/>
      <b-icon name="topical" v-if="useApplication.isTopical" class="el-icon-right" height="30px"/>
      <b-icon name="internal" v-if="useApplication.isInternal" class="el-icon-right" height="30px"/> -->
    </el-button>
    <div>{{ useApplication.instructions }}</div>
  </div>
</template>

<script>
export default {
  props: {
    useApplication: {
      type: Object,
      default: () => {},
    },
  },
  methods: {},
};
</script>

<style scoped>
.el-icon-right {
  margin-left: 5px;
}
.button-m {
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>
