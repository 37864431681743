import { render, staticRenderFns } from "./CatalogCategories.vue?vue&type=template&id=cd1232fc&scoped=true&"
import script from "./CatalogCategories.vue?vue&type=script&lang=js&"
export * from "./CatalogCategories.vue?vue&type=script&lang=js&"
import style0 from "./CatalogCategories.vue?vue&type=style&index=0&id=cd1232fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd1232fc",
  null
  
)

export default component.exports