<template>
  <div>
    <el-menu
      v-if="visible"
      ref="menu"
      class="el-menu"
      :default-active="activeIndex"
      mode="horizontal"
      background-color="#44ce9d"
      text-color="#fff"
      active-text-color="#fff"
      router
      @select="handleSelect"
    >
      <el-row type="flex" justify="space-between" style="outline: none">
        <el-col :xs="{ span: '6' }" :sm="{ span: '2' }">
          <img
            class="image"
            src="@/assets/images/Droplii_logo-white.png"
            @click="navHome"
          />
        </el-col>
        <el-col :xs="{ span: '16' }" :sm="{ span: '8' }" :lg="{ span: '4' }">
          <el-submenu v-if="profile" :hide-timeout="0" index="1">
            <template slot="title">
              <el-avatar
                v-if="profile.picture"
                :size="45"
                :src="profile.picture.url"
                class="picture-icon"
              >
              </el-avatar>
              <font-awesome-icon v-else icon="user" class="user-icon" />
              <span>{{ profile.name }}</span>
            </template>
            <el-menu-item index="/profile">Profile</el-menu-item>
            <el-menu-item index="/feedback">Send Feedback</el-menu-item>
            <!-- S -->
            <!--   <el-menu-item
              v-if="subscription && subscription.hasPaymentInfo"
              :index="$route.path"
              @click="openPortal"
              >Billing</el-menu-item
            > -->
            <div @click="$store.commit('user/LOGOUT')">
              <el-menu-item :index="$route.path">Logout</el-menu-item>
            </div>
          </el-submenu>

          <!-- <el-menu-item
              v-if="!profile.IsMentor"
              @click="navToUpgrade"
              :index="$route.path"
            >Upgrade Now</el-menu-item>-->
        </el-col>
      </el-row>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeIndex: "1",
      visible: true,
    };
  },
  computed: {
    ...mapGetters({
      profile: "user/profile",
      subscription: "user/subscription",
      portalSession: "user/portalSession",
    }),
  },
  methods: {
    navHome() {
      this.$router.push({ name: "Home" }).catch(() => {
        /* ignore redundant home navigation when users click droplii logo more than one time */
      });
    },
    navToUpgrade() {
      window.location.href = "https://droplii.com/upgrade-pricing/";
    },
    openPortal() {
      let chargebeeInstance = window.Chargebee.init({
        site: "droplii-test",
      });
      chargebeeInstance.setPortalSession(() => this.portalSession);
      let cbPortal = chargebeeInstance.createChargebeePortal();
      cbPortal.open({
        close() {
          //close callbacks
        },
      });
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath)
      // console.log(this.$refs.menu)
      // this.$refs.menu.close(key)
      // this.$refs.menu.closeMenu(1)
      // this.$refs.menu.close(key)
      // this.$refs.menu.closeMenu(1)
      // this.$refs.menu.handleSubmenuClick([key, keyPath])
      return keyPath;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/element-variables.scss";
.user-icon {
  padding: 10px;
  margin: 10px;
}
.image {
  height: 50px;
  width: 120px;
}
.color {
  background-color: $--color-primary;
  color: $--color-primary;
}
.white-m {
  color: white !important;
  margin-left: 20px;
  font-size: 20px !important;
}
</style>
<style>
.el-menu {
  height: 55px;
}
.picture-icon {
  margin-right: 15px;
  margin-left: -15px;
}
.el-submenu__title {
  margin-left: -50px !important;
}
.el-submenu__icon-arrow {
  font-size: 25px !important;
  margin-top: -13px !important;
  color: aliceblue;
}
</style>
