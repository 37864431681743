<template>
  <div>
    <el-form label-width="80px">
      <el-form-item>
        <div slot="label"></div>
        <el-input
          v-model="testimonial"
          type="textarea"
          placeholder="add a testimonial..."
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button size="small" type="primary" @click="onAddTestimonial"
      >Add</el-button
    >
    <el-button size="small" @click="$emit('cancel')">Cancel</el-button>
  </div>
</template>

<script>
import AddTestimonialMutation from "@/graphql/mutations/AddTestimonialMutation.graphql";
import GetRecipeQuery from "@/graphql/queries/GetRecipe.graphql";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["user", "recipe"],
  data() {
    return {
      testimonial: "",
    };
  },
  methods: {
    onAddTestimonial() {
      this.$apollo.mutate({
        mutation: AddTestimonialMutation,
        variables: {
          text: this.testimonial,
          author: this.$store.state.user.user.profile.id,
          recipe: this.recipe.id,
        },
        refetchQueries: [
          {
            query: GetRecipeQuery,
            variables: {
              id: this.recipe.id,
              profile: this.$store.state.user.user.profile.id,
            },
          },
        ],
      });
      //hide add testimonial textField
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>
