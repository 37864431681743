<template>
  <div id="app" :style="backgroundStyle">
    <el-container>
      <notification />
      <el-header class="user-header" :height="!authPage ? 'auto' : '0px'">
        <user-header v-if="!authPage" />
        <navigation v-if="!authPage" />
      </el-header>
      <el-container>
        <el-main>
          <h1
            v-if="!authPage && $route.name !== 'Recipe' && $route.name !== 'builder' && $route.name !== 'Profile' && $route.name"
            class="hidden-sm-and-up route-title"
          >
            {{ $route.name }}
          </h1>
          <div v-show="isContentPage">
            <b-search v-if="!authPage"></b-search>
            <br />
          </div>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Notification from "@/components/Notification";
import Navigation from "@/components/Navigation";
import UserHeader from "@/components/UserHeader";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { Notification, Navigation, UserHeader },
  data() {
    return {
      backgroundImage: require("./assets/images/wave.png"),
      backgroundImage2: require("./assets/images/essentials-oils.jpg"),
    };
  },
  computed: {
    ...mapGetters(["user", "uses", "access"]),
    authPage() {
      const route = this.$route.name;
      return (
        route === "Signup" ||
        route === "Login" ||
        route === "Signup Share" ||
        route === 'Successful Signup' ||
        route === "Upgrade" ||
        route === "Reset Password" ||
        route === "Confirm Reset" ||
        route === "AppleIssue"
      );
    },
    isContentPage() {
      const route = this.$route.name;
      return (
        route === "Home" ||
        route === "Catalog" ||
        route === "Uses and Recipes" ||
        route === "Recipe Category" 
      );
    },
    backgroundStyle() {
      const share = this.$route.name === "Signup Share";
      if (this.authPage) {
        return {
          backgroundColor: "#E6E7E8",
          "background-image": "url(" + this.backgroundImage + ")",
          "background-position": "bottom",
          "background-repeat": share ? "no-repeat" : "no-repeat",
          "background-size": share ? "100% 65%" : "100% 65%",
          "background-attachment": "fixed",
        };
      } else {
        return {
          backgroundColor: "white",
        };
      }
    },
    color() {
      return this.authPage ? "#44ce9d" : "white";
    },
  },
  watch: {
    subscriptionStatus(status) {
      if (status.Status === 0 && !this.authPage) {
        //not finished creating
        // this.$router.push({ name: 'Checkout' })
      } else if (status.Status === 2 || status.Status > 1) {
        //cancelled = 2, greater than two
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100%;
  height: auto;
  width: 100%;
}

body,
html {
  height: 100%;
  padding: 0%;
  margin: 0%;
}
.user-header {
  width: 100%;
  padding: 0% !important;
}
a {
  text-decoration: none !important;
  color: #44ce9d;
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
a:visited {
  text-decoration: none !important;
  color: #44ce9d;
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
*:focus {
  outline: none;
}
/* 
To enable smooth scrolling in safari
*/
.el-table--scrollable-y .el-table__body-wrapper {
  -webkit-overflow-scrolling: touch;
}
.el-dialog {
  -webkit-overflow-scrolling: touch;
}
.route-title {
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: 500;
}
</style>
