<template>
  <el-card :shadow="shadow" class="recipe-card" @click.native="onSelectRecipe">
    <div slot="header">
      <span class="title">{{ recipes.name }}</span>
    </div>
    <span> </span>
    <br />
    <span>{{ recipes.instructions.substring(0, 95) }}...</span>
  </el-card>
</template>
<script>
import GetAllRecipes from "@/graphql/queries/GetAllRecipes.graphql";
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["recipes", "shadow"],
  apollo: {
    recipes: {
      query: GetAllRecipes,
    },
  },
  methods: {
    onSelectRecipe() {
      this.$router.push({
        name: "PremiumRecipe",
        params: { premiumId: this.recipes.id },
      });
    },
  },
};
</script>

<style scoped>
.recipe-card {
  cursor: pointer;
}
.title {
  font-weight: 525;
}
</style>