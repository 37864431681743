<template>
  <div>
    <el-row>
      <el-col :sm="{span: 12, offset: 6}">
        <img class="image" src="@/assets/images/oil-logo.png" />
      </el-col>
    </el-row>
    <el-row align="middle" type="flex" justify="center">
      <el-col :sm="{span: 12}" :lg="{span: 8}">
        <div class="star-left">
          <img class="star-size" src="@/assets/images/starLeft.png" alt />
        </div>
        <el-card>
          <br />
          <b slot="header">CONGRATULATIONS!</b>
          <div>
            <p>You are being gifted the droplii 2.0 + Oil Magic app! This $14.99 app is our FREE GIFT for you!</p>
            <el-col>
              <p>The app includes:</p>
              <p>- droplii user curated content, uses, & recipes</p>
              <p>- Protocols from the Essential Oil Magic book</p>
              <p>- Continued updates from the droplii community</p>
              <b>No purchase required. This is our gift for you</b>
              <p>.</p>
            </el-col>
            <br />
          </div>
        </el-card>
        <div class="card">
          <img class="star star-size" src="@/assets/images/starRight.png" alt />
        </div>
      </el-col>
    </el-row>
    <br />
    <el-row align="middle" type="flex" justify="center">
      <el-col :sm="{span: 12}" :lg="{span: 8}">
        <el-card class="box-card">
          <oil-magic-form />
          <p>Questions? Contact us at:</p>
          <p>Howdy@droplii.com</p>
          <img class="thumbimg" src="@/assets/images/thumbs.jpg" alt />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import OilMagicForm from '@/components/auth/OilMagicForm'
export default {
  components: {
    OilMagicForm
  },
  mounted() {
    this.$ga.page('/oilmagic')
  }
}
</script>

<style scoped>
.image {
  max-width: 30%;
}
.star-left {
  position: relative;
  width: 100px;
  height: 100px;
  margin: -50px;
  z-index: 1;
  left: 20px;
  top: 50px;
}
.star-right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 100px;
}
.star {
  position: absolute;
  right: -5px;
  top: -50px;
}
.card {
  position: relative;
  z-index: 1;
}
.star-size {
  max-width: 50px;
}
.thumbimg {
  position: relative;
  width: 90%;
  height: 70%;
}
</style>

