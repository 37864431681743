<template>
  <div>
    <div v-if="purchases.mentor">
      <el-col :sm="{ span: 6 }" :class="mobileSelected">
        <el-button round icon="el-icon-plus" @click="navToCreate('manual')"
          >Contact</el-button
        >
        <br />
        <list
          v-loading="!contacts"
          :listData="contactListWithName"
          @select="handleSelectContact"
        />
        <br />
        <!-- <export-to-c-s-v-button class="m-50" :data="contacts">Download Contacts</export-to-c-s-v-button> -->
      </el-col>
      <el-col ref="useCaseCol" :sm="{ span: 17 }" :offset="1">
        <router-view :contactId="selectedContactId" />
      </el-col>
      <el-dialog :visible.sync="createDialog" width="90%">
        <h1>How would you like to add a contact?</h1>
        <br />
        <el-button type="primary" @click="navToCreate('manual')"
          >Manually</el-button
        >
        <!-- <el-button type="primary">From Mentor Code</el-button> -->
        <!-- <el-button type="primary">From CSV</el-button> -->
      </el-dialog>
    </div>
    <div v-else>
      <empty-contact />
    </div>
  </div>
</template>

<script>
import List from "@/components/List";
import EmptyContact from "@/components/contacts/EmptyContact";
//import ExportToCSVButton from "@/components/contacts/ExportToCSVButton";
import GetContacts from "@/graphql/queries/GetContacts.graphql";
import { mapGetters } from "vuex";

export default {
  components: {
    List,
    EmptyContact,
    // ExportToCSVButton,
  },
  apollo: {
    contacts: {
      query: GetContacts,
      variables() {
        return {
          id: this.$store.state.user.user.profile.id,
        };
      },
      skip() {
        return !this.$store.state.user.user;
      },
      update(data) {
        return data.profile.contacts;
      },
    },
  },
  data() {
    return {
      createDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      purchases: "user/purchases",
    }),
    contactListWithName() {
      if (!this.contacts) return [];
      return this.contacts.map((c) =>
        Object.assign(c, { name: c.firstName + " " + c.lastName })
      );
    },
    selectedContactId() {
      return this.$route.params.contactId;
    },
    mobileSelected() {
      const isSelected = this.selectedContactId;
      return { "hidden-xs-only": isSelected };
    },
  },
  methods: {
    handleSelectContact(contact) {
      this.$router.push({ path: "/contact/" + contact.id }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    navToCreate(createType) {
      this.$router.push({
        name: "Create Contact",
        params: { type: createType },
      });
    },
  },
};
</script>

<style scoped>
.m-50 {
  margin-top: 50px;
}
</style>
