<template>
  <el-menu
    default-active="home"
    router
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    mode="horizontal"
  >
    <el-row type="flex" justify="right" style="outline: none">
      <el-col></el-col>
      <el-menu-item index="/home">
        <span slot="title">
          <b-icon
            height="22px"
            :name="selected.includes('/home') ? 'home2' : 'home1'"
          />
          <span class="hidden-xs-only title">Home</span>
        </span>
      </el-menu-item>
      <el-menu-item index="/catalog">
        <b-icon
          height="22px"
          :name="selected.includes('/catalog') ? 'oils2' : 'oils1'"
        />
        <span slot="title" class="hidden-xs-only title">Catalog</span>
      </el-menu-item>
      <el-menu-item
        v-if="purchases.mentor || purchases.basic || isBasic"
        index="/useCatagories"
      >
        <b-icon
          height="22px"
          :name="selected.includes('/useCatagories') ? 'recipes2' : 'recipes1'"
        />
        <span slot="title" class="hidden-xs-only title">Recipes</span>
      </el-menu-item>
      <el-menu-item v-else index="/basic">
        <b-icon
          height="22px"
          :name="selected.includes('/basic') ? 'recipes2' : 'recipes1'"
        />
        <span slot="title" class="hidden-xs-only title">Recipes</span>
      </el-menu-item>
      <el-menu-item index="/contacts">
        <b-icon
          height="22px"
          :name="selected.includes('/contacts') ? 'contacts2' : 'contacts1'"
        />
        <span slot="title" class="hidden-xs-only title">Contacts</span>
      </el-menu-item>
      <!-- <el-menu-item index="/chat">
        <b-icon :name="selected.includes('/chat') ? 'chat2' : 'chat1'"/>
        <span slot="title" class="hidden-xs-only">&nbsp; Chat</span>
      </el-menu-item>-->
    </el-row>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isCollapse: false,
    };
  },
  computed: {
    ...mapGetters({
      purchases: "user/purchases",
      isBasic: "user/isBasic",
    }),
    selected() {
      return this.$route.path;
    },
  },
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
}
.el-menu-item {
  text-align: start;
}
.title {
  margin-left: 15px;
  margin-top: 10px;
}
</style>